<div class="row map__container">
    <div class="col-6">
      <div id="mapChart"></div>
    </div>
    <div class="col-6">
      <table *ngIf="data && data.length; else noData" class="table table-hover">
        <thead>
          <tr>
            <th>State</th>
            <th>Total Customers</th>
            <th>Revenue Share</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dataItem of data">
            <td><a class="link" (click)="onMapChartSelect(dataItem)">{{ dataItem.State }}</a></td>
            <td>{{ dataItem.TotalCustomers }}</td>
            <td>{{ dataItem.TotalMonthlyRevenueShare }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #noData>
      <labels>No data to show.</labels>
    </ng-template>
  </div>