import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

@Pipe({
  name: 'smtpValidation'
})
export class SmtpHostValidationPipe implements PipeTransform {
  transform(): any {
    return (control: FormControl) => {
        const hostRegex = /^[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*$/;

        const host = control.value;

        if (!hostRegex.test(host)) {
          return { invalidHost: true };
        }
    
        const hostParts = host.split('.');
        if (hostParts.length < 2 || hostParts.length > 3 || hostParts.some(part => part.length < 2 || part.length > 64)) {
          return { invalidHost: true };
        }
    
        return null;
    };
  }
}
