import { Component, ElementRef, Input, QueryList, ViewChild, ViewChildren, ChangeDetectorRef, OnInit } from '@angular/core';
import { ProposalObject, ProposalSiteObject } from 'app/@core/models/proposal.model';
import { BaseComponent } from 'app/@core/base/base.component';
import { ProposalSiteEditorColumnDefinitionObject } from 'app/@core/models/configuraton.model';
import { FieldType } from 'app/@core/enums/field-type';
import { ProposalStatus } from 'app/@core/enums/proposal-status';
import { LookupService } from 'app/@core/services/lookup.service';
import { ProposalService } from 'app/@core/services/proposal.service';
import { StateLookupObject } from 'app/@core/models/lookup.model';
import { take } from 'rxjs/operators';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { LeadService } from 'app/@core/services/lead.service';
import { LeadSiteObject, LeadSiteTableObject, ProposalSiteUpdateObject} from 'app/@core/models/lead-site.model';
import { LeadSiteService } from 'app/@core/services/lead-site.service';
import { ConfigurationService } from 'app/@core/services/configuration.service';
import { EntityType } from 'app/@core/enums/entity-type';
import { FieldObject } from 'app/@core/models/shared.model';
import { FIELD_PREFIX } from '../../../app.constants';
import { VendorService } from 'app/@core/services/vendor.service';
import { VendorSiteColumnOrder } from '../../../../../@core/models/vendor-site-column-order.model';
import { DecimalPipe } from '@angular/common';
import { CellFormatterService } from '../../../cell-formatter.service';
import { parseISO } from 'date-fns';
import { UpdateLeadAnnualUsageObject } from 'app/@core/models/lead.model';
import { NoteService } from 'app/@core/services/note.service';
import { SharedService } from '../../../shared.service';

@Component({
  selector: 'ngx-proposal-site-manage',
  templateUrl: './proposal-site-manage.component.html',
  styleUrls: ['./proposal-site-manage.component.scss']
})
export class ProposalSiteManageComponent extends BaseComponent implements OnInit{

  // Inputs/Outputs
  @Input() proposal: ProposalObject;
  @Input() leadId: number;
  @Input() vendorId: number;
  @Input() title: string = this.translate('SITE_ASSOCIATEDSITES');

  @Input() isNewProposal: boolean;
  @Input() isCompletedProposal: boolean = false;
  @Input() waitingForConfirmation: boolean = false;
  @Input() ongoingSignedProposals: Array<ProposalObject>;
  @Input() editable: boolean = false;

  @Input() customConvert: boolean;
  @Input() includeCustomFields: boolean = false;

  @Input() loadProposalSites: boolean;
  @Input() callInitContentFunction: boolean;
  @Input() openedFromProposal: boolean;

  @ViewChildren('editor') editor: QueryList<any>;
  @ViewChild('table') table: ElementRef;

  // Annual Usage
  leadAnnualUsage: number;
  overridenAnnualUsage: number;
  overrideAnnualUsage: boolean = false;
  leadCurrentAnnualUsage: number;
  isAnnualUsageOverriden: boolean = false;
  updLeadAnnUsageBtnSpinner: boolean = false;

  // Sites
  proposalSites: Array<LeadSiteObject> = new Array<LeadSiteObject>();
  leadSites: Array<LeadSiteTableObject> = new Array<LeadSiteTableObject>();
  displayedSites: Array<LeadSiteTableObject> = new Array<LeadSiteTableObject>();
  listSitesChanged: boolean = false;

  // Fields
  editedValue: string | Date | number | boolean;
  lookupValues: Array<any> = new Array<any>(); //to be changed, low priority
  pristineValue: string | Date | number;
  isDirty: boolean = false;
  hideCell: boolean = false;
  showCustomFields: boolean = false;
  originalStateCode: string;
  states: Array<StateLookupObject> = [];
  isDataModified: boolean = true;

  // other
  schemaColumns: Array<ProposalSiteEditorColumnDefinitionObject> = new Array<ProposalSiteEditorColumnDefinitionObject>();
  readOnly: boolean = false;
  selectAllChecked: boolean = false;
  loading: boolean = false;
  _shouldOverrideCheck: boolean = false;
  tableColsVisibility: Array<boolean> = new Array<boolean>();
  isUpdateLead: boolean = false;
  updateSpinner: boolean = false;

  constructor(
    public dialogRef: NbDialogRef<ProposalSiteManageComponent>,
    private _lookupService: LookupService,
    private _proposalService: ProposalService,
    private _toastrService: NbToastrService,
    private _leadService: LeadService,
    private _leadSiteService: LeadSiteService,
    private _configurationService: ConfigurationService,
    private _vendorService: VendorService,
    private _noteService: NoteService,
    private _decimalPipe: DecimalPipe,
    public formatterService: CellFormatterService,
    private _sharedService: SharedService,
    private cdRef: ChangeDetectorRef
  ){
    super();
  }

  ngOnInit(): void {
    // Set table header columns
    this.setSchemaColumns();

    // Get Lead sites
    this.loadProposalSites ? this.getProposalSites() : this.getLeadSites();

    if (this.isCompletedProposal) {
      return
    }
    this.getCurrentAnnualUsage();
    this._getStatesList();
    this.isAnySelectedSiteInvalid();
  }

  ngAfterViewInit() {
    const _proposal = {...this.proposal};
    this.proposal = {..._proposal};
  }

  // Function to init content
  private _initContent() {
    // Set sites Annual usage
    let sitesAnnualUsage = 0;

    if (this.proposal && this.proposal.ProposalSites) {
      this.displayedSites.forEach(site => {
        let proposalSite = this.proposal.ProposalSites.find((x:ProposalSiteObject) => x.SiteId == site.Id);
        site.AnnualUsage = proposalSite ? proposalSite.AnnualUsage:site.AnnualUsage;
        sitesAnnualUsage += proposalSite ? proposalSite.AnnualUsage : 0;
      })
    }

    if (this.customConvert && this.proposal && this.proposal.Sites) {
      this.displayedSites.forEach(site => {
        site.isSelected = this.proposal.Sites.some(proposalSite => proposalSite.Id === site.Id);
      })
    }

    this.overridenAnnualUsage = this.isAnnualUsageOverriden ? this.leadAnnualUsage : null; // Set the overridden annual usage based on the check
    this.leadAnnualUsage = this.isAnnualUsageOverriden ? null : sitesAnnualUsage; // Update the lead annual usage based on the check
    this.overrideAnnualUsage =this.proposal? (this.proposal.AnnualUsage > 0):false;

    // Set Selection
    this.selectAllChecked = !this.readOnly && this.displayedSites.every((x) => x.isSelected)

    this.loading = false;
    if(this.isNewProposal){
      return
    }

    this.onSiteSelectionChanged();
    this.shouldOverrideCheck();
  }

  // Function to get additional parameters from schema columns based on property name
  getAdditionalParameters(propertyName: string): any {
    const index = this._proposalService._allSchemaColumns.findIndex(x => x.PropertyName === propertyName);
    return index !== -1 ? this._proposalService._allSchemaColumns[index]?.AdditionalParameters : null;
  }

  // Function to recalculate annual usage
  recalculateAnnualUsage() {
    this.leadCurrentAnnualUsage = 0;
    if (this.isAnnualUsageOverriden) {
      this.leadCurrentAnnualUsage = this.leadAnnualUsage;
    }
    else {
      let selectedSites = this.displayedSites.filter(x => x.isSelected);
      selectedSites.forEach(site => {
        this.leadCurrentAnnualUsage += site.AnnualUsage===null?0:site.AnnualUsage;
      });
    }
  }

  setSchemaColumns() {
    this.schemaColumns = this._proposalService._allSchemaColumns;
    this.updateStateFormat();

    this.readOnly = this.isNewProposal ? false: (this.proposal.Status == ProposalStatus.Confirmed || this.proposal.Status == ProposalStatus.DeletedVisible);
    let findAnnualUsageColumnIndex = this._proposalService._allSchemaColumns.findIndex(column => column.PropertyName == 'AnnualUsage');
    if (findAnnualUsageColumnIndex > -1) {
      this._proposalService._allSchemaColumns[findAnnualUsageColumnIndex].ReadOnly = this.readOnly;
      this.schemaColumns[findAnnualUsageColumnIndex].ReadOnly = this.readOnly;
    }
  }

  updateStateFormat() {
    let state = this.schemaColumns.findIndex(el => el.PropertyName == 'State');
    if (state > -1) {
      this.schemaColumns[state].Format = 5;
    }
  }

  // Function to check if annual usage should be overriden
  shouldOverrideCheck(){
    this._shouldOverrideCheck=this.isNewProposal?false:
      (this.isAnnualUsageOverriden && this.proposal.Status != this.proposalStatuses.WaitingForConfirmation)
  }


  // Function to handle site selection change
  onSiteSelectionChanged() {
    this.selectAllChecked = this.leadSites.every((site) => site.isSelected);
    this.checkSitesLeadChange();
    this.recalculateAnnualUsage();
    this.isAnySelectedSiteInvalid();
  }

  get proposalStatuses(): typeof ProposalStatus {
    return ProposalStatus;
  }

  ifAnySiteIsSelected(): boolean {
    return this.displayedSites.some(site => site.isSelected);
  }

  toggleCustomFieldVisibility() {
    this.showCustomFields = !this.showCustomFields
    if (this.showCustomFields) {
      this.schemaColumns = this._proposalService._allSchemaColumns;
      this.updateStateFormat();
    } else {
      this.schemaColumns = this._proposalService._allSchemaColumns.filter(x => !x.IsExcluded);
      this.updateStateFormat();
    }
  }

  // Function to pipe annual usage
  pipeTotalAnnualUsage() {
    if (!this.leadCurrentAnnualUsage) return this.leadCurrentAnnualUsage
    return this._decimalPipe.transform(this.parseValue(this.leadCurrentAnnualUsage), '1.0-10') || ''
  }

  // Function to parse value
  parseValue(value: any) {
    return value ? parseFloat(value.toString().replaceAll(',', '')) : value;
  }

  // Function to sum lead sites annual usage
  leadSitesSumAnnualUsage(): number {
    let sumAnnualUsage = 0;
    let selectedSites = this.displayedSites.filter(x => x.isSelected);
    selectedSites.forEach(site => {
      sumAnnualUsage += site.AnnualUsage
    });

    return sumAnnualUsage;
  }

  updateProposalSelectedSites() {
    this.proposal.ProposalSites = [];
    this.proposal.Sites = [];

    this.displayedSites.forEach(el => {
      el['Annual Usage'] = el.AnnualUsage;
      el['Account Number'] = el.AccountNumber;
      el['Zip Code'] = el.ZipCode;
    })

    this.displayedSites.forEach(site => {
      if (site.isSelected) {
        this.proposal.Sites.push(site);
        this.proposal.ProposalSites.push(site);
      }
    })
    // this.attachedDocuments = this.proposal.Sites;
    // this._leadSiteService.setSelectedSites(this.displayedSites.filter((x) => x.isSelected));
  }

  // Function to check sites lead change
  checkSitesLeadChange() {
      this.proposal.ProposalSites =this.displayedSites.map((x:LeadSiteTableObject) => {
        x.isSelected =x.isSelected;
        x.IsProposalSite=x.isSelected;
        return x;
      }).filter(x => x.isSelected);

    let listSelectedSitesIds=this.proposal.ProposalSites.filter(x => x.isSelected).map(({ Id }) => { return Id; });
    let proposalSiteIds = this.proposal.ProposalSites.map(({ Id}) => { return Id; });

    this.listSitesChanged = JSON.stringify(proposalSiteIds) == JSON.stringify(listSelectedSitesIds)?false:true;
  }

  // Function to close dialog
  closeDialog() {
    if(this.isNewProposal){
      this.dialogRef.close();
      return;
    }

    this.updateProposalSelectedSites();

    this.proposal.CallUsageApi = false;
    if (this.proposal.Status == 6) {
      this.proposal.Status = 7;
    }

    if (this.proposal.Status == 1) {
      this.proposal.AnnualUsage = this.isUpdateLead ? this.leadCurrentAnnualUsage : this.leadSitesSumAnnualUsage();
    }

    this.dialogRef.close(this.proposal)
  }

  // Function to get tooltip message
   getTooltipMessage(column: any, site: LeadSiteTableObject): string {
    if (column.PropertyName === 'AccountNumber') {
      const state = this.states.find(_state => _state.Code === site.State);
      if (state && state.AccountNumberLimit) {
        return `Account Number must be ${state.AccountNumberLimit} digits`;
      }
    }

    return column.PropertyName === 'ZipCode' ? 'Zip code must be 5 digits' : '';
  }

  // Function to validate input
  validateNumericInput(event: any, i: any, j: any) {
    const inputValue = event.target.value.replace(/[^\d]/g, ''); // Remove any non-digit characters;
    event.target.value = inputValue;
  }

  // Function to select all sites
  selectAll(event) {
    this.displayedSites.forEach((x) => (x.isSelected = this.selectAllChecked))
    this.isDirty = true;
    this.checkSitesLeadChange();
    this.recalculateAnnualUsage();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ SAVE/UPDATE DATA METHODS
  // -----------------------------------------------------------------------------------------------------

  // Functionto update lead annual usage
  updateLeadAnnualUsage(closeDialog: boolean) {
    this.loading = true;
    this.isUpdateLead = true;

    this.updLeadAnnUsageBtnSpinner = true;

    let request: UpdateLeadAnnualUsageObject = new UpdateLeadAnnualUsageObject(
      this.leadId,
      this.overridenAnnualUsage ? this.overridenAnnualUsage : 0
    );

    this._leadService.updateLeadAnnualUsage(request).pipe(take(1)).subscribe({
      next: (response: string) => {
        this._noteService.refreshNotes.next(true);
        this._toastrService.success(response);
        this.updLeadAnnUsageBtnSpinner = false;
        this.overrideAnnualUsage = false;
        this.leadCurrentAnnualUsage = 0;
        if (this.overridenAnnualUsage && this.overridenAnnualUsage != 0) {
          this.leadAnnualUsage = this.overridenAnnualUsage;
          this.isAnnualUsageOverriden = true;
          this.leadCurrentAnnualUsage = this.overridenAnnualUsage;
        }
        else {
          this.isAnnualUsageOverriden = false;
          let selectedSites = this.displayedSites.filter(x => x.isSelected);
          selectedSites.forEach(site => {
            this.leadCurrentAnnualUsage += site.AnnualUsage
          });
        }

        this.shouldOverrideCheck();

        this.loading = false;
        if (closeDialog) {
          this.submit(true);
        }
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.updLeadAnnUsageBtnSpinner = false;
        this.loading = false;
      }
    });
  }

  // Function to save lead sites
  private saveLeadSites(closeDialog: boolean) {
    this.updateProposalSelectedSites();
    localStorage.setItem('responseLeadSites', JSON.stringify(this.proposal.ProposalSites)); // If on new product select should show the updated site details
    this.updateSpinner = true;
    this._leadSiteService.saveLeadSites(this.leadId, this.proposal.ProposalSites).pipe(take(1)).subscribe({
      next: (response: string) => {
        this.updateSpinner = false;
        this.loading = false;
        this._toastrService.success(response);
        this.getLeadSites();
        this.recalculateAnnualUsage();
        this.dialogRef.close(this.proposal);
      },
      error: (error) => {
        this.updateSpinner = false;
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  // Function to submit Proposal Lead Site Details
  submit(result: boolean) {
    this.checkSitesLeadChange();
    this.updateSpinner = true;
    this.loading = true;
    if(this.isNewProposal){
      this._submitInternal(true, result);
      return;
    }
    (!this.isDirty || !result) && !this.listSitesChanged ? this._submitInternal(true, result) : this.saveLeadSiteDetails(true, true, result);
  }

  // Function to save lead site details
  saveLeadSiteDetails(closeDialog: boolean, showAlert: boolean,updateLeadSites:boolean) {
    this.isUpdateLead = false;
    this.saveEditedData();
    this.updateProposalSelectedSites();
    let sitesRequest:ProposalSiteUpdateObject=new ProposalSiteUpdateObject(updateLeadSites,this.proposal.ProposalSites);
    this._leadSiteService.saveProposalSites(this.leadId, this.proposal.Id, sitesRequest).pipe(take(1)).subscribe({
      next: (response: string) => {
        closeDialog = false;
        this._sharedService.refreshManageTable.next();
        this.saveLeadSites(true);
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loading = false;
      }
    });
  }

  // Function to internal submit dialog data
  private _submitInternal(closeDialog: boolean, updateLeadSites:boolean = false) {
    if(this.isNewProposal){
      this.loading = false;
      this.updateProposalSelectedSites();
      this.dialogRef.close(this.proposal);
      return;
    } else {
      closeDialog = false;
      this.saveLeadSites(true);
    }

    //If this is update of the lead sites
    this.proposal.CallUsageApi = false;
    this.proposal.updateLeadSites=updateLeadSites;

    this.proposal.Sites = this.displayedSites.filter((x) => x.isSelected);
    if (this.proposal.Status == 6) {
      this.proposal.Status = 7;
    }

    if (this.proposal.Status == 1 && !this.isUpdateLead) {
      this.proposal.AnnualUsage = this.leadSitesSumAnnualUsage();
    }

    if (this.customConvert) {
      this.proposal.AnnualUsage = this.leadCurrentAnnualUsage;
    }

    this.proposal.RemoveSigned = true;
    if (closeDialog) {
      this.dialogRef.close(this.proposal)
    }

    this.shouldOverrideCheck()
  }


  // -----------------------------------------------------------------------------------------------------
  // @ GET DATA METHODS
  // -----------------------------------------------------------------------------------------------------

  // Get Lead Sites
  getLeadSites() {
    this.loading = true;

    this._leadSiteService.getLeadSites(this.leadId || this.proposal.LeadId).pipe(take(1)).subscribe({
      next: (response: Array<LeadSiteTableObject>) => {
        this.leadSites = response;
        this.displayedSites = this.leadSites; //By default displayed sites are the lead sites

        if (this.proposal && this.proposal.ProposalSites) {
          this.displayedSites.forEach((site, i) => {
            let findSelected = this.proposal.ProposalSites.find(el => el.Id == site.Id || el.SiteId == site.Id);
            if (findSelected) {
              this.displayedSites[i] = findSelected;
              this.displayedSites[i].isSelected = true;
              this.displayedSites[i].PropertyName = site.PropertyName;
            }
            else {
              this.displayedSites[i].isSelected = false;
            }

          })
        }

        this.recalculateAnnualUsage();
        this.selectAllChecked = this.displayedSites.every((x) => x.isSelected);

        this.loading = false;
        if (this.includeCustomFields) {
          this.getSiteCustomFields();
          return;
        }

        this._initContent();
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loading = false;
      }
    })
  }

  ngAfterViewChecked() {
    console.log(this.displayedSites)
  }

  // Function to get proposal sites
  getProposalSites(): void {
    this.loading = true;
    this._proposalService.getProposalSites(this.proposal.Id).pipe(take(1)).subscribe({
      next: (response: Array<LeadSiteObject>) => {
        this.proposalSites = response;
        this.getLeadSites();
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.loading = false;
      }
    })
  }

  // Function to get states list
  _getStatesList(): void {
    this._lookupService.getStates().pipe(take(1)).subscribe({
      next: (response: Array<StateLookupObject>) => {
        this.states = response;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  // Function to get current annual usage
  getCurrentAnnualUsage() {
    this.loading = true;
    this._leadService.getCurrentAnnualUsage(this.leadId).pipe(take(1)).subscribe({
      next: (response: number) => {
        this.leadAnnualUsage = response;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  // Function to get site custom fields
  getSiteCustomFields() {
    this.loading = true;
    this._configurationService.getCustomFields(EntityType.Site).pipe(take(1)).subscribe({
      next: (response: Array<FieldObject>) => {
        this._proposalService._allSchemaColumns = this._proposalService._allSchemaColumns.concat(
          response.map((x) => {
            return {
              PropertyName: `${FIELD_PREFIX}${x.FieldId}`,
              Title: x.Name,
              Format: x.FieldType,
              AdditionalParameters: x.AdditionalParameters,
              ColumnId: x.FieldId,
              IsExcluded: true
            }
          })
        );
        if(this.vendorId){
          this.getVendorSitesTableColumns();
        }
        this.loading = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  // Function to get vendor's site table columns order
  getVendorSitesTableColumns(): void {
    this._vendorService.getVendorsSiteTableColumnsOrder(this.vendorId).pipe(take(1)).subscribe({
      next: (data: Array<VendorSiteColumnOrder>) => {
        if (data.length > 0) {
          this._proposalService._allSchemaColumns = data.map((x) => {
            return {
              PropertyName: x.PropertyName,
              Title: x.Title,
              Format: x.FieldType,
              ColumnId: x.ColumnId,
              IsExcluded: x.IsExcluded,
              AdditionalParameters: x.AdditionalParameters ? x.AdditionalParameters : this.getAdditionalParameters(x.PropertyName),
            }
          });
        }

        this.schemaColumns = this._proposalService._allSchemaColumns.filter(x => !x.IsExcluded);
        this.updateStateFormat();
        this._initContent();
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }


  // -----------------------------------------------------------------------------------------------------
  // @ FIELDS ACTIONS (EDIT, UPDATE, VALIDATE)
  // -----------------------------------------------------------------------------------------------------

  // Function to handle on cell edit event
  onCellEdit(event, site, column) {
    if (!this.isEditable(site, column) || site.edit === column.PropertyName) return

    this.saveEditedData()

    this.pristineValue = this.formatterService.getRawColumnValue(site, column)
    this.editedValue = this.pristineValue

    if (this.editedValue && column.Format == FieldType.Date && !(this.editedValue instanceof Date)) {
      this.editedValue = parseISO(this.editedValue.toString())
    }

    if (column.Format == FieldType.Select) {
      this.lookupValues = (column.AdditionalParameters || '').split('\n')
      if(column.PropertyName === "State") {
        this.lookupValues = this.states.map(state => state.Name);
        this.editedValue = this.states.find(state => state.Code === this.editedValue)?.Code;
        this.originalStateCode = this.editedValue;
      }
    }

    site.edit = column.PropertyName;
    site[column.Title] = this.editedValue;
    site.editName = column.Title;
    this.isDataModified = true;
    this.isAnySelectedSiteInvalid();
    this.cdRef.detectChanges();
  }

  // Function to save edited data
  saveEditedData() {
    if (this.editedValue instanceof Date) {
      this.editedValue = this.editedValue ? this._toISOStringWithoutTimeZone(this.editedValue) : null;
    }
    this.leadSites.filter((x) => x.edit).forEach((site) => {
      if (site.edit.startsWith(FIELD_PREFIX)) {
        let fieldId = site.edit.replace(FIELD_PREFIX, '')
        let fieldName = site.editName;
        let field = (site.Fields || []).find((x) => x.FieldId == fieldId)
        if (field) {
          field.Value = typeof this.editedValue === "boolean"
          ? (this.editedValue ? "true" : "false")
          : (this.editedValue ? this.editedValue.toString() : null);
          field.FieldName = fieldName;
        } else {
          site.Fields = site.Fields || []
          site.Fields.push({ FieldId: fieldId, Value: this.editedValue ? this.editedValue.toString() : null, FieldName : fieldName })
        }
      } else {
        if (site.edit === 'State') {
          let stateObj = this.states.find(state => state.Name === this.editedValue);
          this.editedValue = stateObj ? stateObj.Code : this.originalStateCode;
        }
        site[site.edit] = this.editedValue;
      }

      if ((this.editedValue || this.pristineValue) && this.editedValue != this.pristineValue) {
        site.pristineValues = site.pristineValues || []
        if (!site.pristineValues.some((x) => x.PropertyName == site.edit))
          site.pristineValues.push({ PropertyName: site.edit, Value: this.pristineValue })

        this.isDirty = true;
        this.isDataModified = true;
      }
      else {
        this.isDataModified = false;
      }

      if (site.edit == 'AnnualUsage') {
        this.recalculateAnnualUsage()
      }

      site.edit = null
    })
  }

  DateTimeInputChange(event) {
    this.isDirty = true
    this.editedValue = event
  }

  isEditable(site, column) {
    return this.editable && !column.readOnly
  }

  isAnyEditing() {
    let anyEditing: boolean = false;
    this.displayedSites.forEach(el => {
      if (el.edit) {
        anyEditing = true;
      }
    })
    return anyEditing;
  }

  isModified(site, column) {
    return site.pristineValues && site.pristineValues.some((x) => x.PropertyName == column.PropertyName);
  }

  // Function to pipe date to string without time zone
  private _toISOStringWithoutTimeZone(date) {
    let tzOffset = date.getTimezoneOffset()
    return new Date(date.getTime() - tzOffset * 60 * 1000).toISOString().replace('.000Z', '')
  }

  // Function to prevent pasting anything but 5 maximum digits to zipcode input field
  validatePaste(column: any, event: ClipboardEvent, site: LeadSiteTableObject): void {
    if(column.PropertyName === 'ZipCode') {
      event.preventDefault();
      const pastedNumbers = event.clipboardData.getData('text/plain').replace(/[^\d]/g, ''); // Remove non-numeric characters
      const pastedValue = pastedNumbers.substring(0, 5); // Take only the first 5 numbers

      this.editedValue = pastedValue;
    } else if (column.PropertyName === 'AccountNumber') {
      const state = this.states.find(_state => _state.Code === site.State);
      if (state && state.AccountNumberLimit) {
        event.preventDefault();
        const pastedNumbers = event.clipboardData.getData('text/plain').replace(/[^\d]/g, ''); // Remove non-numeric characters
        const pastedValue = pastedNumbers.substring(0, state.AccountNumberLimit); // Take only the first `AccountNumberLimit` numbers

        this.editedValue = pastedValue;
      }
    }
  }

  // Function to check if account number is valid
  isValidInputNumber(site: LeadSiteTableObject, column: any): boolean {
    const requiredFields = ['Address', 'City', 'State', 'EndDate', 'AnnualUsage'];
    // Check if the column is one of the required fields and validate if it is not empty
    if (requiredFields.includes(column.PropertyName)) {
      return !!site[column.PropertyName]; // Ensure the field is not empty
    }
    if (column.PropertyName === 'AccountNumber' || column.PropertyName === 'ZipCode') {
      if (column.PropertyName === 'AccountNumber') {
        const state = this.states.find(_state => _state.Code === site.State);
        if (state && state.AccountNumberLimit) {
          const accountNumberLimit = state.AccountNumberLimit;
          return (site[column.PropertyName] || '').toString().length === accountNumberLimit;
        }
      } else if (column.PropertyName === 'ZipCode') {
        const zipCode = (site[column.PropertyName] || '').toString();
        return zipCode.length === 5;
      }
    }
    return true; // Return true for other columns
  }

  // Function to handle click event outside of the edited cell
  onClickOutsideEditedCell(event, site, column) {
    if (event.classList.contains('editable') || this._isCalendarNode(event)) return
    this.saveEditedData()
  }

  // Function to check is calendar node
  private _isCalendarNode(element) {
    let currentElement = element
    while (currentElement.parentNode) {
      if (currentElement.parentNode.nodeName == 'NB-DATEPICKER-CONTAINER') return true
      currentElement = currentElement.parentNode
    }

    return false
  }

  // Function to check input value if it is less than 5 digits set it to empty string
  checkInputValue(column: any, site: LeadSiteTableObject) {
    if(column.PropertyName === 'ZipCode') {
      if (this.editedValue.toString().length != 5) {
        this.editedValue = '';
      }
    } else if (column.PropertyName === 'AccountNumber') {
      const state = this.states.find(_state => _state.Code === site.State);
      if (state && state.AccountNumberLimit) {
          const accountNumberLimit = state.AccountNumberLimit;
          if (this.editedValue.toString().length != accountNumberLimit) {
              this.editedValue = '';
          }
      }
    }
  }

  // Function to validate entering only numbers
  validateInput(column: any, event: KeyboardEvent, site: LeadSiteTableObject) {
    if(column.PropertyName === 'ZipCode') {
      const pattern = /[0-9]/;
      if (!pattern.test(event.key)) {
        event.preventDefault();
      }
    } else if(column.PropertyName === 'AccountNumber') {
      const state = this.states.find(_state => _state.Code === site.State);
      if (state && state.AccountNumberLimit) {
        const inputValue = (event.target as HTMLInputElement).value;
        const accountNumberLimit = state.AccountNumberLimit;
        if (inputValue.length >= accountNumberLimit) {
          event.preventDefault();
        }
      }
    }
  }

  // Function to handle numeric input change
  onNumericInputChange(event) {
    this.isDirty = true
    this.editedValue = event

    if (!this.editedValue) return this.editedValue

    return this.editedValue ? this._decimalPipe.transform(this.parseValue(this.editedValue), '1.0-10') || '' : null
  }

  // Function to handle date time input change event
  onDateTimeInputChange(event) {
    this.isDirty = true
    this.editedValue = event
  }

// Function to check if any selected site is invalid
  isAnySelectedSiteInvalid(): boolean {
    return this.displayedSites.some(site => site.isSelected && (
      !this.isValidInputNumber(site, { PropertyName: 'AccountNumber' }) ||
      !this.isValidInputNumber(site, { PropertyName: 'ZipCode' }) ||
      !this.isValidInputNumber(site, { PropertyName: 'Address' }) ||
      !this.isValidInputNumber(site, { PropertyName: 'City' }) ||
      !this.isValidInputNumber(site, { PropertyName: 'State' }) ||
      !this.isValidInputNumber(site, { PropertyName: 'AnnualUsage' }) ||
      !this.isValidInputNumber(site, { PropertyName: 'EndDate' })
    ));
  }

  /**
   * Function to parse address
   */
  private parseAddressComponent(event, type, shortNameNeeded = false): string {
    let component = event.address_components.find((x) => x.types.indexOf(type) !== -1)
    if (component) return shortNameNeeded ? component['short_name'] : component['long_name']

    return ''
  }

  /**
 * Function to set address
 */
  setAddress(event, site: LeadSiteTableObject) {
    const streetNumber = this.parseAddressComponent(event, 'street_number');
    const route = this.parseAddressComponent(event, 'route');
    const city = this.parseAddressComponent(event, 'locality');
    const state = this.parseAddressComponent(event, 'administrative_area_level_1', true);
    const zipCode = this.parseAddressComponent(event, 'postal_code');

    site.Address = `${streetNumber} ${route}`.trim();
    site.City = city;
    site.State = state;
    site.ZipCode = zipCode;

    this.cdRef.detectChanges();
  }

  onAddressTyped(site: LeadSiteTableObject) {
    site.Address = '';
    site.City = '';
    site.State = '';
    site.ZipCode = '';
  }
}
