import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common'
import { Injectable, EventEmitter } from '@angular/core'
import { parseISO } from 'date-fns'
import * as moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { FieldType } from '../settings/sector/field-type.enum'
import { FIELD_PREFIX } from './app.constants'
import { toNumber } from '@amcharts/amcharts4/.internal/core/utils/Type'

@Injectable()
export class CellFormatterService {
  onFilterChange: EventEmitter<any> = new EventEmitter()

  currentContext: any
  reportsSchemaColumns: BehaviorSubject<[]> = new BehaviorSubject(null);

  currencyList:Array<string> = ['MonthlyRevenue','TermRevenue','CompanyPrice', 'VendorPrice', 'FinalPrice', 'CompanyPricePerMcf', 'CompanyPricePerCcf'];

  constructor(private decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe, private datePipe: DatePipe) {}

  getRawColumnValue(entity, column) {
    if (column.PropertyName.startsWith(FIELD_PREFIX)) {
      let field = (entity.Fields || []).find((x) => x.FieldId == column.PropertyName.replace(FIELD_PREFIX, ''))
      return field ? field.Value : null
    } else {
      return entity[column.PropertyName]
    }
  }

  getColumnValue(entity, column) {
    // Revenues
    // 1. Monthly
    if (column && column.PropertyName && column.PropertyName === 'MonthlyRevenue') {
      if (entity.MonthlyRevenue) {
        let response: any = this.decimalPipe.transform(this.parseValue(entity.MonthlyRevenue), '1.0-10') || ''
        return '$' + response;
      }
      else if (entity.EffectiveAnnualUsage) {
        let monthlyUnit = entity.EffectiveAnnualUsage / 12;
        let price = entity.CompanyPrice * monthlyUnit;
        let response: any = Math.round((price + Number.EPSILON) * 100) / 100;
        response = this.decimalPipe.transform(this.parseValue(response), '1.0-10') || ''
        return '$' + response;
      }
    }
    // 2. Term
    if (column && column.PropertyName && column.PropertyName === 'TermRevenue') {
      if (entity.TermRevenue) {
        let response: any = this.decimalPipe.transform(this.parseValue(entity.TermRevenue), '1.0-10') || ''
        return '$' + response;
      }
      else if (entity.EffectiveAnnualUsage) {
        let monthlyUnit = entity.EffectiveAnnualUsage / 12;
        let price = entity.CompanyPrice * monthlyUnit * entity.Term;
        let response: any = Math.round((price + Number.EPSILON) * 100) / 100;
        response = this.decimalPipe.transform(this.parseValue(response), '1.0-10') || ''
        return '$' + response;
      }
    }
    // 3. Annual Usage
    if (column && column.PropertyName && (column.PropertyName === 'AnnualUsage' || column.PropertyName == "EffectiveAnnualUsage")
    && entity.AnnualUsage!='' && entity.AnnualUsage!=null) {
        return this.decimalPipe.transform(this.parseValue(entity.AnnualUsage), '1.0-10') || ''
    }

    // 4. Action Icons
    if (column && column.PropertyName && column.PropertyName === 'Action Icons') {
      return null
    }

     // 5. Document Name and Document Type
     if (column && column.PropertyName && (column.PropertyName === 'TemplateName')) {
         return entity.DisplayName;
     }

    // Vendor Price, Company Price and Product Price
    if (column && column.PropertyName
      //Check if the name of the column.PropertyName is in the currencyList
      && (this.currencyList.includes(column.PropertyName))
      //Check if this is not empty value And 0
      && entity[column.PropertyName]!=null && entity[column.PropertyName]!='0'
    ){
      let value = entity[column.PropertyName]
      let response: any = this.decimalPipe.transform(this.parseValue(entity[column.PropertyName]), '1.0-10') || ''
      return '$' + response
    }

    // if SignedDate, show date + time
    if (column && column.PropertyName && (column.PropertyName === 'SignedDate' || column.PropertyName === 'EnvelopeViewedDate'|| column.PropertyName === 'ProposalDate')) {
      if (column.PropertyName == "ProposalDate") {
        return this.datePipe.transform(new Date(entity[column.PropertyName]), 'M/d/yy, h:mm a') 
      }
      column.Format = FieldType.DateTime;
    }

    // ORder #
    if (column && column.PropertyName && column.PropertyName === 'Order #') {
      return entity['OrderId'];
    }

    // Order Type
    if (column && column.PropertyName && column.PropertyName === 'Order Type') {
      return entity['TypeName'];
    }

    // Primary Contact
    if (column && column.PropertyName && column.PropertyName === 'ContactFirstName') {
      return entity.DefaultContactFirstName ? entity.DefaultContactFirstName : '';
    }
    if (column && column.PropertyName && column.PropertyName === 'ContactLastName') {
      return entity.DefaultContactLastName ? entity.DefaultContactLastName : '';
    }
    if (column && column.PropertyName && column.PropertyName === 'ContactEmail') {
      return entity.DefaultContactEmail ? entity.DefaultContactEmail : '';
    }
    if (column && column.PropertyName && column.PropertyName === 'ContactPhoneNumber') {
      return entity.DefaultContactPhoneNumber ? entity.DefaultContactPhoneNumber : '';
    }
    if (column && column.PropertyName && column.PropertyName === 'ContactTitle') {
      return entity.DefaultContactTitle ? entity.DefaultContactTitle : '';
    }

    if (column && column.PropertyName === 'CustomerExistingCharge') {
      return entity.ExistingCharge;
    }
    if (column && column.PropertyName === 'EffectiveCurrentEndDate') {
      if (entity.CurrentEndDate) {
         return this.datePipe.transform(entity.CurrentEndDate, `MM/dd/yyyy`)
      }
    }


    let value = this.getRawColumnValue(entity, column)
    try {
      switch (column.Format) {
        case FieldType.Numeric:
          // return value ? this.decimalPipe.transform(value, `1.${column.Precision || 0}-${column.Precision || 0}`) : null
          return value ? value : null
        case FieldType.Currency:
          return value
            ? '$' + value
            : null
          // return value
          //   ? this.currencyPipe.transform(value, 'USD', 'symbol', `1.2-2`)
          //   : null
        case FieldType.Date:
          return this.datePipe.transform(value, `MM/dd/yyyy`)
        case FieldType.DateTime:
          // const timeZoneOffset = new Date().getTimezoneOffset();
          // let dateMoment = moment(parseISO(value), 'MM/dd/yyyy HH:mm');
          // let correctSignedDate = (dateMoment.add(-timeZoneOffset, 'minutes'));

          return this.datePipe.transform(value, `MM/dd/yyyy 'at' h:mm aa`)
        default:
          return value
      }
    } catch (e) {
      // console.warn(`Unable to parse ${column.Format} value: ${value}`)
      return value
    }
  }

  parseValue(value: any) {
    return value? parseFloat(value.toString().replaceAll(',', '')):value;
  }

  getRawColumnValueMapping(entity, column) {

    if (column.PropertyName.startsWith(FIELD_PREFIX)) {
      let field = entity[column.PropertyName.replace(FIELD_PREFIX, '')];
      return field ? field : null
    } else {
      return entity[column.PropertyName]
    }
  }
  getColumnValueMapping(entity, column) {
    let value = this.getRawColumnValueMapping(entity, column)

    try {
      switch (column.Format) {
        case FieldType.Numeric:
          return value ? this.decimalPipe.transform(value, `1.${column.Precision || 0}-${column.Precision || 0}`) : null
        case FieldType.Currency:
          return value
            ? this.currencyPipe.transform(value, 'USD', 'symbol', `1.${column.Precision || 2}-${column.Precision || 2}`)
            : null
        case FieldType.Date:
          return this.datePipe.transform(value, `MM/dd/yyyy`)
        case FieldType.DateTime:
          return this.datePipe.transform(value, `MM/dd/yyyy 'at' h:mm aa`)
        default:
          return value
      }
    } catch (e) {
      // console.warn(`Unable to parse ${column.Format} value: ${value}`)
      return value
    }
  }

  updatePrecision(price, unit) {
    let companyParts = (price || 0).toString().split('.')
    let companyPrecision = companyParts.length > 1 ? companyParts[1].length : 0

    let unitParts = (unit || 0).toString().split('.')
    let unitPrecision = unitParts.length > 1 ? unitParts[1].length : 0

    let precision = Math.max(companyPrecision, unitPrecision);
    return precision
  }

  round(value, precision) {
    let precisionNumber = Math.pow(10, precision)

    let epsolon=Number.EPSILON
    let rounded= Math.round((value +epsolon ) * precisionNumber) / precisionNumber
    return rounded;
  }

}
