<nb-card [nbSpinner]="isLoading">
  <nb-card-header>Recipient <nb-icon class="close-icon" icon="close" (click)="dialogRef.close()"></nb-icon></nb-card-header>
  <nb-card-body>
    <form [formGroup]="recipientForm">
      <div class="field">
        <label>First Name</label>
        <input type="text" nbInput fullWidth fieldSize="tiny" class="input-full-width size-tiny status-basic shape-rectangle" formControlName="firstName" />
      </div>
      <div class="field">
        <label>Last Name</label>
        <input type="text" nbInput fullWidth fieldSize="tiny" class="input-full-width size-tiny status-basic shape-rectangle" formControlName="lastName" />
      </div>
      <div class="field">
        <label>Email</label>
        <input type="text" nbInput fullWidth fieldSize="tiny" class="input-full-width size-tiny status-basic shape-rectangle" formControlName="email" (input)="emailInputChanged()"/>
        <p class="error_message" *ngIf="recipientForm.get('email').invalid && recipientForm.get('email').touched">Invalid email format</p>
      </div>

      <div class="field">
        <label>Phone Number</label>
        <input type="tel" nbInput fullWidth fieldSize="tiny" class="input-full-width size-tiny status-basic shape-rectangle" formControlName="phoneNumber"
        mask="(000) 000-0000"
        />
        <p class="invalid_phone" *ngIf="recipientForm.get('phoneNumber').hasError('numericLength')">Invalid phone number, must be <b>10</b> digits. <b>Ex: (123) 456-7890</b>.</p>
      </div>
      <div class="field">
        <label>Title</label>
        <input type="text" nbInput fullWidth fieldSize="tiny" class="input-full-width size-tiny status-basic shape-rectangle" formControlName="position" />
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton size="tiny" status="danger" (click)="dialogRef.close()">Cancel</button>
    <button
      nbButton
      size="tiny"
      status="success"
      [disabled]="recipientForm.invalid || isLoading"
      [nbSpinner]="isLoading"
      (click)="submit()"
    >
      Submit
    </button>
  </nb-card-footer>
</nb-card>
