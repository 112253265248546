import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { BaseComponent } from 'app/@core/base/base.component';
import { TranslateService } from 'app/@core/services';

@Component({
  selector: 'ngx-site-save-change-confirmation',
  templateUrl: './site-save-change-confirmation.component.html',
  styleUrls: ['./site-save-change-confirmation.component.scss']
})
export class SiteSaveChangeConfirmationComponent extends BaseComponent implements OnInit {

  constructor(
    public dialogRef: NbDialogRef<SiteSaveChangeConfirmationComponent>,
    translateService: TranslateService) {
      super()
    }

  ngOnInit(): void {}

  submit(result) {
    this.dialogRef.close(result)
  }
}
