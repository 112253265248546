import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { LayoutService } from 'app/@core/utils';
import { NotificationsService } from '../notifications.service';
import { NotificaitonAlert } from './notifications.model';
import { Router } from '@angular/router';
import { NotificationsDataService } from '../notifications-data.service';
import { BaseComponent } from 'app/@core/base/base.component';
import { takeUntil } from 'rxjs/operators'
import { NbToastrService } from '@nebular/theme'

@Component({
  selector: 'ngx-notifications-sidenav',
  templateUrl: './notifications-sidenav.component.html',
  styleUrls: ['./notifications-sidenav.component.scss']
})
export class NotificationsSidenavComponent extends BaseComponent implements OnInit{

  @Input() trigger: any;
  @Input() notBars: [];
  @Input() notTitle: string;
  @Input() notGroups: Array<NotificaitonAlert>;
  @Input() notificationsSpinner: boolean;
  @Output() removeNot: EventEmitter<any> = new EventEmitter();
  @Output() removeAllNotif: EventEmitter<void> = new EventEmitter();
  @Output() unreadAllNotif: EventEmitter<void> = new EventEmitter();
  @Output() pageScrolled: EventEmitter<void> = new EventEmitter();
  @Input() eventIndex: number;
  @Input() groupIndex: number;
  isLoading: boolean = true

  notificationList: Array<NotificaitonAlert> = [];
  atLeastOneUnread: boolean = false;

  constructor(
    private layoutService: LayoutService,
    private notificationsService: NotificationsService,
    private _notificationsDataService: NotificationsDataService,
    private _toastrService: NbToastrService,
    private router: Router,
    private _cdRef: ChangeDetectorRef,
  ) {
    super()
   }

  ngOnInit(): void {
    this.layoutService.notificationList.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      data => {
        if (data) {
          let countUnread = data.find(el => !el.isRead);
          this.atLeastOneUnread = countUnread ? true : false;
          this._cdRef.detectChanges();
        }
      }
    )
  }

  onScroll() {
    this.pageScrolled.emit();
  }

  closeSidenav() {
    this.layoutService.toggleNotificationSidenav.next();
  }

  closeAlert(event: any) {
    this.removeNot.emit({
      eventIndex: event.eventIndex,
      groupIndex: event.groupIndex
    });
  }

  removeAll() {
    this.removeAllNotif.emit();
  }

  unreadAll() {
    this.unreadAllNotif.emit();
  }

  seeAll() {
    this.router.navigate(['./pages/notificaitons']);
    this.layoutService.toggleNotificationSidenav.next();
  }

  updateList() {
    this.notificationsService.notGroups = this.notGroups;
    this.notificationsService.notGroups = this.notificationsService.removeDuplicatesById(this.notificationsService.notGroups);
    this.layoutService.notificationList.next(this.notGroups);
  }

  markAsRead(notGroup: NotificaitonAlert, i: number, type: string) {
    this._notificationsDataService.markAsReadNotification(notGroup.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.notGroups[i].isRead = true;
      let countUnread = this.notGroups.find(el => el.isRead);
      this.atLeastOneUnread = countUnread ? true : false;
      this.notificationsService.refreshAllNotificationsPage.next();
      this.updateList();
    }, error => {
      this._toastrService.danger(error.error?.Content);
    })
  }

  markAsUnread(notGroup: NotificaitonAlert, i: number, type: string) {
    this._notificationsDataService.markAsUnreadNotification(notGroup.id).pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.notGroups[i].isRead = false;
      let countUnread = this.notGroups.find(el => !el.isRead);
      this.atLeastOneUnread = countUnread ? true : false;
      this.notificationsService.refreshAllNotificationsPage.next();
      this.updateList();
    }, error => {
      this._toastrService.danger(error.error?.Content);
    })
  }

}
