import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbFormFieldModule,
  NbInputModule,
  NbSpinnerModule,
} from '@nebular/theme'
import { NbEvaIconsModule } from '@nebular/eva-icons'
import { NbSecurityModule } from '@nebular/security'

import { FooterComponent, HeaderComponent, IntegrationStatusComponent, MapChartComponent, SearchComponent } from './components'
import { OneColumnLayoutComponent } from './layouts'
import { FormsModule } from '@angular/forms';
import { DatetimeComponent } from './components/datetime/datetime.component';
import { TrendChartComponent } from './components/trend-chart/trend-chart.component'
import { CoreModule } from 'app/@core/core.module'

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbFormFieldModule,
  NbInputModule,
  NbButtonModule,
  NbSpinnerModule,
  CoreModule.forChild()
]
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  IntegrationStatusComponent,
  OneColumnLayoutComponent,
  SearchComponent,
  DatetimeComponent,
  MapChartComponent,
  TrendChartComponent
]
@NgModule({
  imports: [FormsModule, CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot({
          name: 'darkgradient',
        }).providers,
      ],
    }
  }
}
