<div class="sidenav">
  <div class="sidenav-overlay" [ngClass]="{'overlay-bg' : trigger === 'in'}" (click)="closeSidenav()">
  </div>
  <div class="sidenav-content" 
  [ngClass]="{'content-bg' : trigger === 'in'}" 
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScroll()">
    <nb-icon status="default" icon="close" class="close" (click)="closeSidenav()"></nb-icon>

    <p class="title">Notifications</p>

    <hr>

    <div class="d-flex justify-content-end mb-2">
      <button nbButton size="tiny" *ngIf="notGroups.length > 0" class="mr-15" (click)="seeAll()">See all</button>
      <button nbButton size="tiny" *ngIf="notGroups.length > 0 && atLeastOneUnread" (click)="removeAll()" [nbSpinner]="notificationsSpinner" [disabled]="notificationsSpinner">Mark all as read</button>
      <button nbButton size="tiny" *ngIf="notGroups.length > 0 && !atLeastOneUnread" (click)="unreadAll()" [nbSpinner]="notificationsSpinner" [disabled]="notificationsSpinner">Mark all as unread</button>
    </div>

    <div *ngFor="let notGroup of notGroups; let i = index" class="p-relative">
      <div [ngClass]="notGroup.isRead ? 'read-signal' : 'unread-signal'" class="not-signal"></div>
      <div>
        <nb-icon icon="more-horizontal-outline" class="menu-icon" [matMenuTriggerFor]="menuNotification"></nb-icon>
        <mat-menu #menuNotification="matMenu">
          <button mat-menu-item (click)="markAsRead(notGroup, i)" *ngIf="!notGroup.isRead">
              <mat-icon>checkmark-outline</mat-icon>
              <span>Mark as read</span>
          </button>
          <button mat-menu-item (click)="markAsUnread(notGroup, i)" *ngIf="notGroup.isRead">
            <mat-icon>mark_as_unread</mat-icon>
            <span>Mark as unread</span>
          </button>
      </mat-menu>
    </div>

      <div class="actions">
        <p class="group-title">{{notGroup.title}}</p>
      </div>

      <hr class="mt-5">

      <div class="not-bars">
        <div *ngFor="let bar of notGroup.events; let j = index">
          <div class="not-bar" [style.background]="bar.typeName == 'Docusign' ? '#3366ff' : '#ffffff'">
            <ngx-notification-bar [bar]="bar" [i]="i" [j]="j" [isAlert]="false" [showHideIcon]="true" (closeAlert)="closeAlert($event)"></ngx-notification-bar>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

