export enum FieldType {
  Text = 0,
  Numeric = 1,
  Currency = 2,
  Date = 3,
  DateTime = 4,
  Select = 5,
  Checkbox = 6,
  ShortText = 7,
  Longtext = 8,
  ToggleSwitch = 9,
  Multiselect = 10,
  Url = 11
}
