import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ColumnDefinitionObject } from '../models/configuraton.model';
import { EntityType } from '../enums/entity-type';
import { LeadFiledsData } from '../models/lead.model';
import { defaultAccount, defaultSenderAccount } from '../models/email.model';
import { FieldObject } from '../models/shared.model';
import { VendorSiteColumnOrder } from '../models/vendor-site-column-order.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl;

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to get all schema columns
   */
  public getAllSchemaColumns(type: EntityType): Observable<Array<ColumnDefinitionObject>> {
    return this._httpClient.get<Array<ColumnDefinitionObject>>(`${this._apiUrl}/configuration/columns/${type}`).pipe(
      map((data: Array<ColumnDefinitionObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get default schema columns
   */
  public getDefaultSchemaColumns(type: EntityType): Observable<Array<ColumnDefinitionObject>> {
    return this._httpClient.get<Array<ColumnDefinitionObject>>(`${this._apiUrl}/configuration/schema/${type}`).pipe(
      map((data: Array<ColumnDefinitionObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to save schema columns
   */
  public saveSchemaColumns(type: EntityType, request: Array<ColumnDefinitionObject>): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/configuration/schema/${type}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get site custom fields
   */
  public getCustomFields(type: EntityType): Observable<Array<FieldObject>> {
    return this._httpClient.get<Array<FieldObject>>(`${this._apiUrl}/configuration/field/${type}`).pipe(
      map((data: Array<FieldObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get lead form inputs
   */
  getLeadFormInputs (): Observable<LeadFiledsData> {
    return this._httpClient.get<LeadFiledsData>(`${this._apiUrl}/configuration/lead/form/fields`).pipe(
      map((data: LeadFiledsData) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update lead form inputs
   */
  updateLeadFormInputs(request: LeadFiledsData): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/configuration/lead/form/fields`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get default account
   */
    public getDefaultAccount() : Observable<defaultAccount> {
    return this._httpClient.get<defaultAccount>(`${this._apiUrl}/configuration/news/recipient`).pipe(
      map((data: defaultAccount) => data)
    );
  }

  /**
   * Function to update default account
   */
  public updateDefaultAccount(obj: defaultAccount): Observable<boolean> {
    return this._httpClient.put<boolean>(`${this._apiUrl}/configuration/news/recipient`, obj).pipe(
      map((data: boolean) => data)
    );
  }

  /**
   * Function to get default sender account
   */
  public getDefaultSenderAccount() : Observable<defaultSenderAccount> {
    return this._httpClient.get<defaultSenderAccount>(`${this._apiUrl}/configuration/news/sender`).pipe(
      map((data: defaultSenderAccount) => data)
    );
  }

  /**
   * Function to update default sender account
   */
  public updateDefaultSenderAccount(obj: defaultSenderAccount): Observable<boolean> {
    return this._httpClient.put<boolean>(`${this._apiUrl}/configuration/news/sender`, obj).pipe(
      map((data: boolean) => data)
    );
  }

  /**
   * Function to create field details
   */
  public createFieldDetails(entityType: EntityType, request: FieldObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/configuration/field/${entityType}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update field details
   */
  public updateFieldDetails(entityType: EntityType, request: FieldObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/configuration/field/${entityType}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update field settings
   */
  public updateFieldSettings(request: FieldObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/configuration/field/settings`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to delete field
   */
  public deleteField(fieldId: number): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/configuration/field/${fieldId}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to update site order
   */
  public saveVendorSiteColumnOrder(vendorId: number, order: any): Observable<VendorSiteColumnOrder> {
    return this._httpClient.post<VendorSiteColumnOrder>(`${this._apiUrl}/configuration/site/order/${vendorId}`, order)
        .pipe(
            map(response => response),
            catchError(this._handleError)
        );
  }

  /**
   * Function to retrieve site order by vendorId
   */
  public getVendorSiteColumnOrder(vendorId: number): Observable<VendorSiteColumnOrder[]> {
    return this._httpClient.get<VendorSiteColumnOrder[]>(`${this._apiUrl}/configuration/site/order/${vendorId}`)
        .pipe(
            map(response => response),
            catchError(this._handleError)
        );
}
}
