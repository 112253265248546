import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'app/@core/base/base.component';
import { SharedService } from '../shared.service';
import { Contact } from 'app/pages/lead/profile/manage/customer-view-settings-form/customer-settings.model';
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'ngbd-tooltip-basic',
  templateUrl: './tooltip-basic.html',
  styleUrls: ["./tooltip-basic.scss"]
})
export class NgbdTooltipBasic extends BaseComponent implements OnInit {

    @Input() contact: any;
    @Input() tooltip: any;
    @Output() selectedContact: EventEmitter<any> = new EventEmitter()

    sampleHtml = ``;

  constructor(
    private _sharedService: SharedService,
  ){
    super()
  }

  ngOnInit() {
    this._sharedService.selectedContact.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (data: Contact) => {
        if (data && data.Id === this.contact.Id) {
          this.contact.FirstName = data.FirstName;
          this.contact.LastName = data.LastName;
          this.contact.Email = data.Email;
          this.contact.Position = data.Position;
          this.contact.PhoneNumber = data.PhoneNumber;
          this.updateContactTooltip();
        }
      }
    )
  }

  ngAfterViewInit() {
    this.updateContactTooltip();
  }

  updateContactTooltip() {
    this.sampleHtml = `
    Name: ${this.contact.FirstName} ${this.contact.LastName} ${this.contact.Phone ? '<br>' : ''}
    ${this.contact.Phone ? 'Phone: ' + this.contact.Phone : ''} ${this.contact.Email ? '<br>' : ''}
    ${this.contact.Email ? 'Email: ' + this.contact.Email : ''}
    `
  }

  onContactSelected(contact: any) {
    this.selectedContact.emit(contact);
  }
}

