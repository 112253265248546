import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { environment } from './../../../environments/environment'
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { StorageAreaFileObject } from '../models/file.model';
import { DocusignTemplateObject } from '../models/docusign.model';

@Injectable()
export class DocumentService {

  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl;

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  /**
   * Function to parse error blob
   */
  private _parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      }
    });
    reader.readAsText(err.error);
    return obs;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to get uplodaded files from blob storage
   */
  getFilesFromBlobStorage(leadId: number): Observable<Array<StorageAreaFileObject>> {
    return this._httpClient.get<Array<StorageAreaFileObject>>(`${this._apiUrl}/lead/${leadId}/files`).pipe(
      map((data: Array<StorageAreaFileObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to download uploaded file from blob storage
   */
  downloadFileFromBlobStorage(leadId: number, fileId: number): Observable<Blob> {
    return this._httpClient.get(`${this._apiUrl}/document/${leadId}/files/blob/${fileId}`, { responseType: 'blob'}).pipe(
      map((data: Blob) => data),
      catchError(this._parseErrorBlob));
  }

  /**
   * Function to delete uploaded file from blob storate
   */
  deleteFileFromBlobStorage(leadId: number, fileId: number): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/document/${leadId}/files/blob/${fileId}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to delete multiple uploaded file from blob storate
   */
  deleteMultipleFilesFromBlobStorage(leadId: number, fileIds: Array<number>): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}/document/${leadId}/files/blob/delete`, fileIds).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  getAll(): Observable<Array<DocusignTemplateObject>> {
    return this._httpClient.get<Array<DocusignTemplateObject>>(`${this._apiUrl}/document`). pipe(map((data:Array<DocusignTemplateObject>) => data),
    catchError(this._handleError)
    );
  }
}
