import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss']
})
export class DatetimeComponent implements OnInit {
  time = new Date()

  constructor() { }

  ngOnInit(): void {
    setInterval(() => {
      this.time = new Date();
   }, 1000);
  }

}
