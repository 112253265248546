import { AngularEditorConfig } from "@kolkov/angular-editor"

export const FIELD_PREFIX = 'field_'

export const ANGULAR_EDITORCONFIG: AngularEditorConfig = {
    editable: true,
    toolbarHiddenButtons: [
      ['undo', 'redo', 'subscript', 'superscript', 'indent', 'outdent', 'fontName'],
      [
        'heading',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  }