import { Component, Input, OnInit } from '@angular/core';
import { NbToastrService, NbDialogRef } from '@nebular/theme';
import { ProposalStatus } from 'app/@core/enums/proposal-status';
import { ProposalService } from 'app/@core/services/proposal.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ngx-delete-proposal',
  templateUrl: './delete-proposal.component.html',
  styleUrls: ['./delete-proposal.component.scss']
})
export class DeleteProposalComponent implements OnInit {

  /**
   * Inputs/Outputs
   */
  @Input() proposalId: number;
  @Input() leadId: number;
  @Input() proposalStatus: ProposalStatus;

  /**
   * Public variables
   */
  keepVisible: boolean = false
  isDeleting: boolean = false

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor and DI
  // -----------------------------------------------------------------------------------------------------
  constructor(private _proposalService: ProposalService,
              private _toastrService: NbToastrService,
              public dialogRef: NbDialogRef<DeleteProposalComponent>) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {}


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to delete proposal
   */
  delete() {
    this.isDeleting = true;
    this._proposalService.deleteProposal(this.leadId, this.proposalId, this.keepVisible).pipe(take(1)).subscribe({
      next: (response: string) => {
        this._toastrService.success(response);
        this.isDeleting = false;
        this.dialogRef.close(response)
      },
      error: (error) =>{
        this._toastrService.danger(error.error.Content);
        this.isDeleting = false;
        this.dialogRef.close();
      }
    })
  }
}
