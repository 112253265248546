import { DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { LeadSiteTableObject } from 'app/@core/models/lead-site.model';
import { LookupObject, StateLookupObject } from 'app/@core/models/lookup.model';
import { LookupService } from 'app/@core/services/lookup.service';
import { take } from 'rxjs/operators';
import { FieldType } from 'app/@core/enums/field-type';
import { parseISO } from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-import-file-table-mapping',
  templateUrl: './import-file-table-mapping.component.html',
  styleUrls: ['./import-file-table-mapping.component.scss']
})
export class ImportFileTableMappingComponent implements OnInit {

  fileData: Array<any> = [];
  fileDataSites: Array<any> = [];
  fileCoumns: Array<string> = [];
  fileName: string;
  selectAllChecked: boolean = true;
  anySelected: boolean = false;
  states: Array<StateLookupObject> = [];
  isLoading: boolean = true;
  isDirty: boolean;
  lookupValues: Array<any> = new Array<any>();
  vendorList: Array<LookupObject> = new Array<LookupObject>();
  originalStateCode: string;
  @ViewChild('dateInput') dateInput: ElementRef;
  isDatePickerOpen: boolean = false;
  fields: any = [];
  isBillingZipCodeValid:boolean = true
  constructor(
    public dialogRef: NbDialogRef<ImportFileTableMappingComponent>,
    private _cdRef: ChangeDetectorRef,
    private  _lookupService: LookupService,
    private _toastrService: NbToastrService,
    private _decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this._getVendorList();
    this._getStatesList();
    this.getConfigurationFields();
  }

  ngAfterViewInit() {
    this.onSelectionChanged(null, 0);

    if (this.fileData && this.fileData.length > 0) {
      this.fileDataSites = this.fileData.map(childArray => {
        return childArray.map((item, i) => {
          return {
            value: this.formatValue(item, this.fileCoumns && this.fileCoumns[i] ? this.fileCoumns[i] : ''),
            valueOriginal: this.formatValue(item, this.fileCoumns && this.fileCoumns[i] ? this.fileCoumns[i] : ''),
            edit: '',
            columnName: this.fileCoumns && this.fileCoumns[i] ? this.fileCoumns[i] : '',
            Format: this.setFieldFormat(this.fileCoumns && this.fileCoumns[i] ? this.fileCoumns[i] : null),
            FieldId: 0,
            additionalParameters: [],
            isSelected: true,
            isValid: true
          };
        });
      });
    }

    if (this.states && this.states.length > 0) {
      this.validateRowsSelection();
    }
    this._cdRef.detectChanges();
  }

  getConfigurationFields(): void {
    this.http.get(`${environment.baseApiUrl}/configuration/field/4`).subscribe((result: any) => {
      this.fields = result;

      this.fileDataSites.forEach((row, i) => {
        row.forEach((col, j) => {
          let findIndex = this.fields.findIndex(el => el.Name == col.columnName);
          if (findIndex > -1) {
            this.fileDataSites[i][j].Format = this.fields[findIndex].FieldType;
            this.fileDataSites[i][j].FieldId = this.fields[findIndex].FieldId;
            let _additionalParams = this.fileDataSites[i][j].additionalParameters = this.fields[findIndex].AdditionalParameters;

            if (_additionalParams) {
              this.fileDataSites[i][j].additionalParameters = _additionalParams.split('\n');
              this.fileDataSites[i][j].additionalParameters.filter(item => item.trim().length > 0);
            }
          }
        })
      });

      let findSelected = this.fileDataSites.find(el => el[0].isSelected == true);
      this.anySelected = findSelected ? true : false;
    });
  }

  formatValue(value, columnName) {
    if (columnName && columnName.toLowerCase().includes('date')) {
      return value ? new Date(value) : '';
    } else {
      return value;
    }
  }

  setPreviewValue(value, columnName) {
    if (columnName && columnName.toLowerCase().includes('date')) {
      return value ? this.datePipe.transform(value, 'MM/dd/yyyy') : '';
    } else {
      return value;
    }
  }

  closeDialog() {
    this.dialogRef.close(event);
  }

  setFieldFormat(field: string): number {
    if (field) {
      const lowerCaseField = field.toLowerCase();
      if (lowerCaseField.includes('date')) {
        return 3;
      } else if (lowerCaseField.includes('loan') || lowerCaseField.includes('bank') || lowerCaseField.includes('state') || lowerCaseField.includes('vendor')) {
        return 5;
      } else if (lowerCaseField.includes('annual')) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

    onSelectionChanged(selected: any, index: number) {
      let findUnselected = this.fileDataSites.find(el => el[0].isSelected == false);
      this.selectAllChecked = findUnselected ? false : true;

      let findSelected = this.fileDataSites.find(el => el[0].isSelected == true);
      this.anySelected = findSelected ? true : false;

      this.validateRowsSelection();
    }

    selectAll(event) {
      this.fileDataSites.forEach(row => {
        row.forEach(col => {
          col.isSelected = event.target.checked;
        })
      })

      this.anySelected = event.target.checked;

      this._cdRef.detectChanges();
    }

    isSelected(index: number) {
      return this.fileData[index][this.fileData.length -1];
    }

    submit() {
      this.fileDataSites = this.fileDataSites.filter(el => el[0].isSelected == true);

      this.fileDataSites.forEach((row, i) => {
        let _row = [];
        row.forEach((col, j) => {
          if (col.Format == 3 && col.value) {
            col.value = this.datePipe.transform(col.value, 'MM/dd/yyyy');
          }

          if (col.Format == 9 && col.value != undefined && col.value != null) {
            col.value = col.value.toString();
          }
          _row.push(col.value ? col.value : undefined)
        });
      })


      this.dialogRef.close(this.fileDataSites)
    }

    _getStatesList(): void {
      this._lookupService.getStates().pipe(take(1)).subscribe({
        next: (response: Array<StateLookupObject>) => {
          this.states = response;
          this.validateRowsSelection();
          this.isLoading = false;
        },
        error: (error) => {
          this._toastrService.danger(error.error.Content);
        }
      })
    }

    isFieldValid(i: number, j: number) {
      if (this.fileDataSites[i][j].columnName == 'Account Number' || this.fileDataSites[i][j].columnName == 'Zip Code' || this.fileDataSites[i][j].columnName == 'Address' || this.fileDataSites[i][j].columnName == 'City' || this.fileDataSites[i][j].columnName == 'Current Vendor' || this.fileDataSites[i][j].columnName == 'Annual Usage' || this.fileDataSites[i][j].columnName == 'End Date' || this.fileDataSites[i][j].columnName == 'State') {
        this.fileDataSites[i][j].isValid = this.validateInitialValue(this.fileDataSites[i][j], i);
        return this.fileDataSites[i][j].isValid;
      } else {
        return true;
      }
    }

    validateRowsSelection() {
      this.fileDataSites.forEach((el, i) => {
        for (let j = 0; j < el.length; j++) {
          let isValid = this.validateInitialValue(el[j], i);

          if (!isValid) {
            el.forEach(col => col.isSelected = false);
            break;
          }
        }
      })

      if (this.fileDataSites && this.fileDataSites.length > 0) {
        this.selectAllChecked = this.fileDataSites.find(el => el[0].isSelected == true);
      }
      this._cdRef.detectChanges();
    }

    validateAllRowsSelection() {
      let anyValidRow: boolean = false;

      if (this.fileDataSites && this.fileDataSites.length > 0) {
        this.fileDataSites.forEach((row, i) => {
          let isValid = this.validateSingleRowSelection(i);
          anyValidRow = isValid ? true : anyValidRow;
        })

        let anySelected = this.fileDataSites.find(el => el[0].isSelected == true);
        this.selectAllChecked = anySelected ? this.selectAllChecked : false;
        this.anySelected = anySelected ? true : false;

        return anyValidRow;
      }
      else {
        this.selectAllChecked = false;
        return false;
      }
    }

    validateSingleRowSelection(i: number) {
      var isValid: boolean = true;

      for (let j = 0; j < this.fileDataSites[i].length; j++) {
        let isValid = this.validateInitialValue(this.fileDataSites[i][j], i);

        if (!isValid) {
          this.fileDataSites[i].forEach(col => col.isSelected = false);
          return false;
        }
      }

      return isValid;
    }

    onDateTimeInputChange(event, i:number, j:number) {
      if (this.fileDataSites[i][j] && this.fileDataSites[i][j].Format == 3) {
        this.fileDataSites[i][j].value = event;
      }
    }

    isValidInputNumber(site, column, colName: string, index: number): boolean {
      if (colName === 'Account Number' || colName === 'Zip Code' || colName == 'Billing Zip Code') {
        if (colName == 'Account Number') {
          let findColIndex = this.fileCoumns.findIndex(el => el == 'State');
          if (findColIndex > -1) {
            const state = this.states.find(state => state.Code === this.fileData[index][findColIndex]);
            if (state && state.AccountNumberLimit) {
              const accountNumberLimit = state.AccountNumberLimit;
              return (site[column.value] || '').toString().length === accountNumberLimit;
            }
          }
        } else if (colName == 'Zip Code' || colName == 'Billing Zip Code') {
          const zipCode = (site[column.columnName] || '').toString();
          return zipCode.length === 5;
        }
      }
      return true;
    }

    onCellEdit(event, column, rowIndex: number, colIndex: number) {
      if (column.value && column.Format == FieldType.Date && !(column.value instanceof Date)) {
        column.value = parseISO(column.value.toString())
      }

      if (column.Format == FieldType.Select) {
        this.lookupValues = (column.AdditionalParameters || '').split('\n')
        if(column.columnName == "State" || column.columnName == "Billing State") {
          this.lookupValues = this.states.map(state => state.Name);
          column.value = this.states.find(state => state.Code === column.value)?.Code;
          column.additionalParameters = this.lookupValues;
          this.originalStateCode = column.value;
        }
        if(column.columnName == "Current Vendor") {
          this.lookupValues = this.vendorList.map(vendor => vendor.Name);
          const vendor = this.vendorList.find(vendor => vendor.Name === column.value);
          column.vendorId = vendor?.Id;
          column.value = vendor?.Name;
          column.additionalParameters = this.lookupValues;
          this.fileDataSites[rowIndex][colIndex].additionalParameters = this.lookupValues;
          this.fileDataSites[rowIndex][colIndex].value = vendor?.Name;
          this.fileDataSites[rowIndex][colIndex].vendorId = vendor?.Id;
        }
      }

      this.fileDataSites.forEach((row, i) => {
        row.forEach((col, j) => {
          col.edit = rowIndex == i && colIndex == j ? true : false;
        })
      })

      setTimeout(() => {
        if (this.dateInput) {
          if (!this.isDatePickerOpen) {
            this.renderer.selectRootElement(this.dateInput.nativeElement).click();
          }
          this.isDatePickerOpen = !this.isDatePickerOpen;
        }
      }, 50)
    }

    showTextInputField(i, j) {
      return this.fileDataSites[i][j].Format == 7 || this.fileDataSites[i][j].Format == 8  || !this.fileDataSites[i][j].Format;
    }


    onSelectChange(event, i, j) {
      if (this.fileDataSites[i][j].columnName == 'State' || this.fileDataSites[i][j].columnName == 'Billing State') {
        let findStateIndex = this.states.findIndex(el => el.Name == event);
        if (findStateIndex > -1) {
          this.fileDataSites[i][j].value = this.states[findStateIndex].Code;
        }
      }
    }

    validateInput(column: any, i:number, event: KeyboardEvent, site) {
      if(column.columnName == 'Zip Code' || column.columnName == 'Billing Zip Code') {
        const pattern = /[0-9]/;
        if (!pattern.test(event.key)) {
          event.preventDefault();
        }
      } else if(column.columnName == 'Account Number') {
        let findState = this.fileDataSites[i].find(el => el.columnName == 'State');
        if (findState) {
          const state = this.states.find(state => state.Code === findState.value);
          if (state && state.AccountNumberLimit) {
            const inputValue = (event.target as HTMLInputElement).value;
            const accountNumberLimit = state.AccountNumberLimit;
            if (inputValue.length >= accountNumberLimit) {
              event.preventDefault();
            }
          }
        }

      }
    }

    validateInitialValue(column, index): boolean {
      if (column.columnName == 'Address' || column.columnName == 'City' || column.columnName == 'End Date' || column.columnName == 'Current Vendor') {
        return column.value && column.value != ' ' ? true : false;
      } else
      if (column.columnName == 'State') {
        const state = this.states.find(state => state.Code == column.value);
        return state ? true : false;
      } else
      if (column.columnName == 'Zip Code') {
        const pattern = /[0-9]/;
        return pattern.test(column.value);
      } else
      if (column.columnName == 'Account Number') {
        const numberValue = Number(column.value);
        if (isNaN(numberValue)) {
          return false;
        }

        let findState = this.fileDataSites[index].find(el => el.columnName == 'State');
        if (findState) {
          const state = this.states.find(state => state.Code === findState.value);
          if (state && state.AccountNumberLimit) {
            const inputValue = column.value;
            const accountNumberLimit = state.AccountNumberLimit;
            return !inputValue || inputValue.length != accountNumberLimit ? false : true;
          } else {
            return false
          }
        } else {
          return false;
        }
      } else
      if (column.columnName == 'Annual Usage') {
        const numberValue = Number(column.value);
        return !isNaN(numberValue) ? true : false;
      } else {
        return true;
      }
    }

    /**
   * Function to get tooltip message
   */
    getTooltipMessage(column: any, site: LeadSiteTableObject, i:number, j:number): string {
      if (column.columnName == 'Account Number') {
        let findState = this.fileDataSites[i].find(el => el.columnName == 'State');
        if (findState) {
          const state = this.states.find(state => state.Code === findState.value);
          if (state && state.AccountNumberLimit) {
            return `Account Number must be ${state.AccountNumberLimit} digits`;
          }
        }
      }

      let zipCode = column.columnName == 'Zip Code' || column.columnName == 'Billing Zip Code' ? true : false;
      return zipCode ? 'Zip code must be 5 digits' : '';
    }

    /**
   * Function to handle numeric input change
   */
    onNumericInputChange(event, value, i:number, j:number) {
      this.isDirty = true
      return value ? this._decimalPipe.transform(this.parseValue(value), '1.0-10') || '' : null;
    }

   /**
   * Function to parse value
   */
    parseValue(value: any) {
      return value ? parseFloat(value.toString().replaceAll(',', '')) : value;
    }

    /**
     * Function to prevent pasting anything but 5 maximum digits to zipcode input field
     */
    validatePaste(column: any, event: ClipboardEvent, site: LeadSiteTableObject, i:number): void {
      if(column.columnName == 'Zip Code' || column.columnName == 'Billing Zip Code') {
        event.preventDefault();
        const pastedNumbers = event.clipboardData.getData('text/plain').replace(/[^\d]/g, '');
        const pastedValue = pastedNumbers.substring(0, 5);

        column.value = pastedValue;
      } else if (column.columnName == 'Account Number') {
        let findState = this.fileDataSites[i].find(el => el.columnName == 'State');
        if (findState) {
          const state = this.states.find(state => state.Code === findState.value);
          if (state && state.AccountNumberLimit) {
            event.preventDefault();
            const pastedNumbers = event.clipboardData.getData('text/plain').replace(/[^\d]/g, '');
            const pastedValue = pastedNumbers.substring(0, state.AccountNumberLimit);
            column.value = pastedValue;
          }
        }


      }
    }

   /**
   * Function to check input value if it is less than 5 digits set it to empty string
   */
     checkInputValue(column: any, event: any, site: LeadSiteTableObject, i:number) {
      if(column.columnName == 'Zip Code') {
        if (column.value.toString().length > 5) {
          column.value = ''
        }
      } else if(column.columnName == 'Billing Zip Code') {
        if (column.value.toString().length > 5) {
          column.value = column.value.toString().slice(0, 5);
          event.target.value = column.value
        }
      }
       else if (column.columnName == 'Account Number') {
        let findState = this.fileDataSites[i].find(el => el.columnName == 'State');
        if (findState) {
          const state = this.states.find(state => state.Code === findState.value);
          if (state && state.AccountNumberLimit) {
            const accountNumberLimit = state.AccountNumberLimit;
            if (!column.value || column.value.toString().length != accountNumberLimit) {
                column.value = '';
            }
          }
        }
      }
        if (site[0].isSelected) {
          if (column.columnName === 'Billing Zip Code') {
            this.isBillingZipCodeValid = (column.value.toString().length === 0 || column.value.toString().length === 5) ? true : false;
          }
        }
    }

   /**
   * Function to validate input
   */
  validateNumericInput(event: any, i: number, j: number) {
    const sanitizedValue = event.target.value.replace(/[^\d]/g, '');

    // Update the value both in the DOM and your fileDataSites array
    event.target.value = sanitizedValue;
    this.fileDataSites[i][j].value = sanitizedValue;

  }

  isModified(column, i:number, j:number) {
    let changed: boolean;

    if (this.fileDataSites[i][j].Format == 3) {
      let dateCurrent = this.fileDataSites[i][j].value ? this.datePipe.transform(this.fileDataSites[i][j].value, 'MM/dd/yyyy') : '';
      let dateOriginal = this.fileDataSites[i][j].valueOriginal ? this.datePipe.transform(this.fileDataSites[i][j].valueOriginal, 'MM/dd/yyyy') : '';
      changed = dateCurrent != dateOriginal
    } else {
      changed = this.fileDataSites[i][j].value != this.fileDataSites[i][j].valueOriginal
    }
    return changed;
  }

  toggleChange(event, i: number, j: number) {
    this.fileDataSites[i][j].value = event.checked;
  }

  hideOnCustomer(column) {
    if ((column == 'End Date' || column == 'Start Date' || column == 'Current Vendor') && this.router.url.includes('customer')) {
      return true
    }
  }

  _getVendorList(): void {
    this._lookupService.getApplicantVendors().pipe(take(1)).subscribe({
      next: (response: Array<LookupObject>) => {
        this.vendorList = response;
        this.isLoading = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }
}
