import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationSettings } from '../notifications-sidenav/notifications.model';
import { NotificationsDataService } from '../notifications-data.service';
import { NbToastrService } from '@nebular/theme'
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-notifications-settings',
  templateUrl: './notifications-settings.component.html',
  styleUrls: ['./notifications-settings.component.scss']
})
export class NotificationsSettingsComponent {

  settingsGroup: FormGroup;
  settings: NotificationSettings = new NotificationSettings();
  isLoading: boolean = true;
  
  constructor(
    private formBuilder: FormBuilder,
    private notificationsDataService: NotificationsDataService,
    private toastrService: NbToastrService,
    private router: Router
  ){
    this.initForm();
    this.getSettingsValues();
  }

  initForm() {
    this.settingsGroup = this.formBuilder.group({
      push: [false],
      email: [false],
      sms: [false],
    });
  }

  subscribeToSettingsChange(){
    this.settingsGroup.valueChanges.subscribe(() => {
      this.saveSettings();
    });
  }

  getSettingsValues() {
    this.notificationsDataService.getNotificationSettings().subscribe(
      (res) => {
        this.settingsGroup.get('push').patchValue(res.UsePushNotification);
        this.settingsGroup.get('email').patchValue(res.UseEmailNotification);
        this.settingsGroup.get('sms').patchValue(res.UseSmsNotification);
        this.isLoading = false;
        this.subscribeToSettingsChange();
      },
      (error) => {
        this.isLoading = false;
        this.toastrService.danger(error.error?.Content? error.error?.Content : error.message);
      }
    )
  }

  saveSettings() {
    this.settings.usePushNotification = this.settingsGroup.get('push').value;
    this.settings.useEmailNotification = this.settingsGroup.get('email').value;
    this.settings.useSmsNotification = this.settingsGroup.get('sms').value;

    this.notificationsDataService.updateNotificationSettings(this.settings).subscribe(
      (res) => {
        this.toastrService.success('Settings successfully updated!')
      },
      (error) => {
        this.toastrService.danger(error.error?.Content? error.error?.Content : error.message);
      }
    )
  }

  backToNotifications() {
     this.router.navigate(['./pages/notificaitons']);
  }
}
