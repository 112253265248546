import { Directive, ElementRef, Renderer2, Input, SimpleChanges, OnChanges } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class TextHighlightDirective implements OnChanges {
  @Input('appHighlight') searchTerm: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.searchTerm) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.el.nativeElement.textContent);
      return;
    }

    this.highlight(this.searchTerm);
  }

  private highlight(text: string) {
    const matchIndex = this.el.nativeElement.textContent.toLowerCase().indexOf(text.toLowerCase());
    if (matchIndex >= 0) {
      const originalText = this.el.nativeElement.textContent;
      const highlightedText = `<span style="background-color: yellow">${originalText.substr(matchIndex, text.length)}</span>`;
      const newText = originalText.replace(originalText.substr(matchIndex, text.length), highlightedText);
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', newText);
    }
  }
}
