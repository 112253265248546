import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme'
import { Clipboard } from '@angular/cdk/clipboard';
import { VendorService } from 'app/@core/services/vendor.service';
import { IntegrationObject } from 'app/@core/models/integration.model';
import { NordVpnGuideComponent } from './nord-vpn-guide/nord-vpn-guide.component';
import { EmailValidationPipe } from 'app/pages/shared/custom-pipes/email-validation.pipe';

@Component({
  selector: 'ngx-integration-editor-dialog',
  templateUrl: './integration-editor-dialog.component.html',
  styleUrls: ['./integration-editor-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class IntegrationEditorDialogComponent implements OnInit {

  /**
   * Inputs & Outputs
   */
  @Input() model: IntegrationObject;
  @Input() isNordVpn: boolean = false;
  @Input() deleteTemplates: boolean = false;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  /**
   * Public variables
   */
  docusignIntegrationForm: FormGroup;
  nordVpnIntegrationForm: FormGroup;
  originModel: IntegrationObject = new IntegrationObject();
  showPassword: boolean = false;
  isFormModified: boolean = false;
  isLoading: boolean = false;
  isUpdate: boolean = false;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor & DI
  // -----------------------------------------------------------------------------------------------------
  constructor(public dialogRef: NbDialogRef<IntegrationEditorDialogComponent>,
              private _formBuilder: FormBuilder,
              private _cdRef: ChangeDetectorRef,
              private _clipboard: Clipboard,
              private _toastrService: NbToastrService,
              private _dialogService: NbDialogService,
              private emailValidationPipe: EmailValidationPipe,
              private _vendorService: VendorService) {
    this._initDocusignIntegrationForm();
    this._initNordVpnIntegrationForm();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {
    this._cdRef.detectChanges();

    this.originModel = this.model === null || this.model === undefined ? new IntegrationObject() : this.model;

    if(!this.isNordVpn) {
      if(this.model.ClientId && this.model.AccountId && this.model.SecretKey) {
        this.isUpdate = true;
      } else {
        this.isUpdate = false;
      }
      this.originModel.Name = "DocuSign";
      this._patchDocusignIntegrationFormValues();
    }
    else {
      this.originModel.Name = "NordVpn";
      this.originModel.ServiceType = 3;
      this._patchNordVpnFormValues();
    }

    this._subscribeToFormChanges();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to subscribe to form changes
   */
  private _subscribeToFormChanges() {
    // Docusign Form
    this.docusignIntegrationForm.valueChanges.subscribe(
      value => {
        if (value.AccountIdNew == this.originModel.AccountId &&
          value.ClientId == this.originModel.ClientId &&
          value.Email == this.originModel.Email &&
          value.Password == this.originModel.Password &&
          value.RedirectURL == this.originModel.RedirectUri &&
          value.SecretKey == this.originModel.SecretKey) {
          this.isFormModified = false
        }
        else {
          this.isFormModified = true
        }
      }
    )

    // NordVPN Form
    this.nordVpnIntegrationForm.valueChanges.subscribe(
      value => {
        if (value.AccessToken == this.originModel.AccessToken) {
          this.isFormModified = false
        }
        else {
          this.isFormModified = true
        }
      }
    )
  }

  /**
   * Function to init docusign integration form
   */
  private _initDocusignIntegrationForm(): void {
    this.docusignIntegrationForm = this._formBuilder.group({
      Name: new FormControl({ value: '', disabled: true }, Validators.required,),
      ApiUrl: new FormControl({ value: '', disabled: true }, Validators.required),
      ClientId: new FormControl('', Validators.required),
      AccountIdNew: new FormControl('', Validators.required),
      SecretKey: new FormControl('', Validators.required),
      RedirectURL: new FormControl({ value: '', disabled: true }, Validators.required),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Password: new FormControl('', Validators.required),
    })
  }

  emailInputChanged() {
    let val = this.docusignIntegrationForm.get('Email').value;
    this.docusignIntegrationForm.get('Email').setErrors(
      val && this.emailValidationPipe.transform()(new FormControl(val)) ? { invalidFormat: true } : null);
  }

  /**
   * Function to init nordvpn integration form
   */
  private _initNordVpnIntegrationForm(): void {
    this.nordVpnIntegrationForm = this._formBuilder.group({
      AccessToken: new FormControl('', Validators.required),
    })
  }

  /**
   * Function to patch docusign integration form values
   */
  private _patchDocusignIntegrationFormValues() {
    this.docusignIntegrationForm.patchValue({
      Name: this.originModel.Name,
      ApiUrl: this.originModel.AuthUri,
      ClientId: this.originModel.ClientId,
      AccountIdNew: this.originModel.AccountId,
      SecretKey: this.originModel.SecretKey,
      RedirectURL: this.originModel.RedirectUri,
      Email: this.originModel.Email,
      Password: this.originModel.Password,
    })
    this._cdRef.detectChanges();
  }

  /**
   * Function to patch nordvpn integration form values
   */
  private _patchNordVpnFormValues() {
    this.nordVpnIntegrationForm.patchValue({
      AccessToken: this.originModel.AccessToken
    })
    this._cdRef.detectChanges();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to close editor dialog
   */
  close() {
    this.dialogRef.close();

  }

  /**
   * Function to toggle password input type
   */
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }


  /**
   * Function to copy input value to clipboard
   */
  copyToClipboard(url: string) {
    this._clipboard.copy(url);
    this._toastrService.success("Copied to clipboard");
  }


  /**
   * Function to open nord vpn access token explanation
   */
  openNordVpnGuide() {
    this._dialogService.open(NordVpnGuideComponent)
  }


  /**
   * Function to submit editor dialog
   */
  submit() {
    if (this.originModel.ServiceType === 1)//Docusign =1, NordVpn = 3
    {
      if(this.isUpdate && this.deleteTemplates) {
        if (window.confirm('Are you sure? this will remove all default templates for your vendors.')) {
          this.submitDocusignIntegrationForm();
        }
      }
      else {
        this.submitDocusignIntegrationForm();
      }
    }

    else
    {
      this.isNordVpn ? this.submitNordVpnForm(): this.submitDocusignIntegrationForm();
    }
  }

  /**
   * Function to submit docusign integration form
   */
  submitDocusignIntegrationForm() {
    this.isLoading = true;

    this.originModel.isDelete = true;
    this.originModel.Name = this.docusignIntegrationForm.get('Name').value;
    this.originModel.BaseApiUrl = this.docusignIntegrationForm.get('ApiUrl').value;
    this.originModel.ClientId = this.docusignIntegrationForm.get('ClientId').value;
    this.originModel.AccountId = this.docusignIntegrationForm.get('AccountIdNew').value;
    this.originModel.SecretKey = this.docusignIntegrationForm.get('SecretKey').value;
    this.originModel.RedirectUri = this.docusignIntegrationForm.get('RedirectURL').value;
    this.originModel.Email = this.docusignIntegrationForm.get('Email').value;
    this.originModel.Password = this.docusignIntegrationForm.get('Password').value;
    this.originModel.AccessToken = null;
    this.originModel.RefreshToken = null;

    if (this.deleteTemplates) {
      this._vendorService.deleteVendorTemplate().pipe(take(1)).subscribe({
        next: (data) => {
          if(data){
          this._toastrService.success('Templates successfully deleted!')

          setTimeout(() => {
            //Send this object to the parent component
            this.dialogRef.close(this.originModel);
            this.isLoading = false
          }, 500);
        }

        },
        error: (error) => {
          this._toastrService.danger(error.error.response);
          this.isLoading = false
        }
      })
    }
    else {
      //Send this object to the parent component
      this.dialogRef.close(this.originModel);
      this.isLoading = false
    }
  }

  /**
   * Function to submit nord vpn form
   */
  submitNordVpnForm() {
    this.originModel.isDelete = true;
    this.originModel.AccessToken = this.nordVpnIntegrationForm.get('AccessToken').value;

    //Send this object to the parent component
    this.dialogRef.close(this.originModel);
  }

}
