<div class="integration">
  <div
    class="integration__indicator warning"
    [ngClass]="{
      warning: checkingStatus,
      success: !checkingStatus && (status && status.IsValid === true) || isNordVpn,
      error: !checkingStatus && status && !status.IsValid && !isNordVpn
    }"
  ></div>
  <div *ngIf="checkingStatus && !isNordVpn; else hasStatus">
    Checking {{ title }} authorisation...
  </div>
  <ng-template #hasStatus>
    <div *ngIf="(status && status.IsValid) || isNordVpn" class="integration__display">
      <span>{{ title }} authorized</span>
      <button nbButton size="tiny" status="info" (click)="resetAuthorization()" *ngIf="!isNordVpn">Reset Authorization</button>
    </div>
    <div *ngIf="status && !status.IsValid" class="integration__display">
      <span *ngIf="!isNordVpn">Your {{ title }} integration is missing or has been expired, please
        authorize yourself</span>
      <button nbButton size="tiny" status="success" (click)="authorizeIntegration()" *ngIf="!isNordVpn">Authorize</button>
    </div>
  </ng-template>
</div>
