import { Component, Input } from '@angular/core'
import { ViewCell } from 'ng2-smart-table'

@Component({
  selector: 'button-view',
  template: ` {{ value | currency: 'USD' }} `,
})
export class CurrencyViewComponent implements ViewCell {
  renderValue: string

  @Input() value: string | number
  @Input() rowData: any
}
