import { HttpClient } from '@angular/common/http'
import { Component, Input, Output, OnInit, EventEmitter, OnDestroy, HostListener, Renderer2  } from '@angular/core'
import { Observable } from 'rxjs/Rx'
import { Subject, Subscription } from 'rxjs'
import { IntegrationService } from 'app/@core/services/integration.service'
import { take, takeUntil } from 'rxjs/operators'
import { OAuthStatusObject } from 'app/@core/models/integration.model'
import { NbToastrService } from '@nebular/theme'

@Component({
  selector: 'ngx-integration-status',
  templateUrl: './integration-status.component.html',
  styleUrls: ['./integration-status.component.scss'],
})
export class IntegrationStatusComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() title: string;
  @Input() id: number;
  @Input() isNordVpn: boolean;
  @Input() autoAuthorize: boolean;
  @Output() authorised: EventEmitter<any> = new EventEmitter();

  status: OAuthStatusObject = new OAuthStatusObject();
  checkingStatus: boolean = true;
  windowHandle: any;
  windowTimeout: number;
  intervalLength: number = 2000;
  pollingLimit: number = 5;
  pollingData: Subscription = new Subscription();

  /**
   * Private variables
   */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor & DI
  // -----------------------------------------------------------------------------------------------------
  constructor(private _httpClient: HttpClient,
              private _integrationService: IntegrationService,
              private _toastrService: NbToastrService,) {
              }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {
    if(!this.isNordVpn) {
      this.checkStatus();
    }
    this._subscribeToAuthorizeDocusignSubject();
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    setTimeout(() => {
      this.pollingData.unsubscribe();
    }, 100)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to subscribe to authorize docusign subject
   */
  private _subscribeToAuthorizeDocusignSubject() {
    this._integrationService.authorizeDocusign.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: (data: boolean) => {
        if(data) {
          if(!this.isNordVpn) {
            this.authorizeIntegration();
          }
        }
      }
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Function to check integration status
   */
  public checkStatus() {
    this._integrationService.checkIntegrationStatus(this.type, this.id).pipe(take(1)).subscribe({
      next: (response: OAuthStatusObject) => {
        this.status = response;
        this.checkingStatus = false;
      },
      error: (error) => {
        if(error.error.Content == "Problem with Docusign service: ") {
          this._toastrService.danger("Unable to process document request: Your docusign integration is not authorized!");
        }
        else {
          this._toastrService.danger(error.error.Content);
        }
      }
    })
  }

  /**
   * Function to reset integration
   */
  public resetAuthorization() {
    this._integrationService.resetIntegration(this.id).pipe(take(1)).subscribe({
      next: (response: string) => {
        this._toastrService.success(response);
        this.checkStatus();
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
      }
    })
  }

  /**
   * Function to authorize integration
   */
  public authorizeIntegration() {
    var options = 'left=100,top=10,width=400,height=600'
    this.windowHandle = window.open(this.status.AuthUri, 'OAUTH2', options)

    let limit = this.pollingLimit;
    this.pollingData = Observable.interval(this.intervalLength)
      .switchMap(() => this._integrationService.checkIntegrationStatus(this.type, this.id).map((res: any) => res))
      .subscribe((response: OAuthStatusObject) => {
        this.status = response
        if (response.IsValid) {
          this.authorised.emit({
            provider: this.type,
            isAuthenticated: true,
          })
          this.pollingData.unsubscribe()
          this.windowHandle.close()
        }
        this.checkingStatus = false;
      })
  }

}
