import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NoteObject } from '../models/note.model';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  /**
   * Public variables
   */
  refreshNotes: Subject<boolean> = new Subject<boolean>();

  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl;

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to get note details
   */
  getNoteDetails(noteId: number): Observable<NoteObject> {
    return this._httpClient.get<NoteObject>(`${this._apiUrl}/note/${noteId}`).pipe(
      map((data: NoteObject) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get lead notes
   */
  getLeadNotes(leadId: number): Observable<Array<NoteObject>> {
    return this._httpClient.get<Array<NoteObject>>(`${this._apiUrl}/lead/${leadId}/note`).pipe(
      map((data:Array<NoteObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to save lead note
   */
  saveLeadNote(request: NoteObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/note`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to delete lead note
   */
  deleteLeadNote(id: number): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/note/${id}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }
}
