<div class="not-bar-frame" [style.background]="barBg()">
  <nb-icon icon="close" (click)="closeBar()" class="close-bar" [style.color]="'#000000'"></nb-icon>

  <p class="bar-title" [style.color]="'#000000'" *ngIf="group.events[0]">
    <nb-icon [style.background]="group.iconBg" [style.color]="group.iconColor" [icon]="group?.icon" class="bar-icon" ></nb-icon>
    {{group.events[0].title}}
  </p>

  <p class="countdown"
    *ngIf="group && group.events.length > 0 && group.events[0].title.includes('queued') && (countDownSeconds > 0 || countDownMinutes > 0)">
    It will be processed in approximately <span><b>{{ countDownMinutes ? countDownMinutes + ' min and ' : ''}} {{countDownSeconds}} sec</b></span></p>

  <ul *ngFor="let event of group.events | slice:1; let i = index" class="p-0">
    <li class="subevent">
        <nb-icon [style.background]="event.statusName != 'Failed' ? '#62a207' : '#f45d52'" [style.color]="'#ffffff'" [icon]="event?.icon" class="sub-notificaiton-icon" ></nb-icon>
        <span>{{event.title}}</span>
      </li>
  </ul>


  <span class="timeago" [style.color]="dateColor()">{{group.timeAgo}}</span>

</div>

