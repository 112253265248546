import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class WhiteSpaceValidatorServiceService {

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (typeof value !== 'string' && typeof value !== 'number' && !(value instanceof Date)) {
        return { leadingWhitespace: true };
      }
      else {
        const stringValue = typeof value === 'string' ? value.replace(/^\s+/, '') : typeof value === 'number' ? value.toString().trim() : value.toISOString().trim();
        const isValid = stringValue.length > 0;
        return isValid ? null : { leadingWhitespace: true };
      }
    };
  }
}
