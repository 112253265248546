import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment'
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DocumentSendObject, DocusignTemplateObject, FormDocumentObject } from '../models/docusign.model';
import { FileObject } from '../models/file.model';

@Injectable({
  providedIn: 'root'
})
export class DocusignService {

  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl;

  constructor(private _httpClient: HttpClient) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  /**
   * Function to parse error blob
   */
  private _parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      }
    });
    reader.readAsText(err.error);
    return obs;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to sync docusign templates
   */
  public syncDocusignTemplates(): Observable<Array<DocusignTemplateObject>> {
    return this._httpClient.put<Array<DocusignTemplateObject>>(`${this._apiUrl}/document/docusign/resettemplates`, null).pipe(
      map((data: Array<DocusignTemplateObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get docusign templates
   */
  public getDocusignTemplates(): Observable<Array<DocusignTemplateObject>> {
    return this._httpClient.get<Array<DocusignTemplateObject>>(`${this._apiUrl}/document`).pipe(
      map((data: Array<DocusignTemplateObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to check queue messages count
   */
  public checkQueueMessagesCount(): Observable<number> {
    return this._httpClient.get<number>(`${this._apiUrl}/document/check/queue/message/count`).pipe(
      map((data: number) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to send docusign template
   */
  public sendDocusignTemplate(request: DocumentSendObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/document/send`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get form documents data
   */
  public getFormDocumentsData(leadId: number): Observable<Array<FormDocumentObject>> {
    return this._httpClient.get<Array<FormDocumentObject>>(`${this._apiUrl}/lead/${leadId}/documents`).pipe(
      map((data: Array<FormDocumentObject>) => data),
      catchError(this._handleError)
    );
  }

    /**
   * Function to get proposal documents data
   */
    public getProposalDocumentsData(proposalId: number): Observable<Array<FormDocumentObject>> {
      return this._httpClient.get<Array<FormDocumentObject>>(`${this._apiUrl}/Document/${proposalId}/documents`).pipe(
        map((data: Array<FormDocumentObject>) => data),
        catchError(this._handleError)
      );
    }

  /**
   * Function to download document
   */
  public downloadDocument(id: number): Observable<Blob> {
    return this._httpClient.get(`${this._apiUrl}/document/${id}/documents`, { responseType: 'blob'}).pipe(
      map((data: Blob) => data),
      catchError(this._parseErrorBlob));
  }

  /**
   * Function to download signed docusign document
   */
  public downloadSignedDocusignDocument(id: string): Observable<Blob> {
    return this._httpClient.get(`${this._apiUrl}/document/${id}/download`, { responseType: 'blob'}).pipe(
      map((data: Blob) => data),
      catchError(this._parseErrorBlob));
  }

  /**
   * Function to delete sent docusign document
   */
  public deleteSentDocusignDocument(id: number): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/document/${id}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to get docusign template preview url
   */
  public getDocusignTemplatePreviewUrl(id: string): Observable<string> {
    return this._httpClient.get<string>(`${this._apiUrl}/document/preview/${id}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }
}
