import { HttpClient } from '@angular/common/http'
import { OnChanges, Component, EventEmitter, Input, Output } from '@angular/core'
import { environment } from 'environments/environment'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_usaAlbersLow from '@amcharts/amcharts4-geodata/usaAlbersLow'

@Component({
  selector: 'ngx-map-chart',
  templateUrl: './map-chart.component.html',
  styleUrls: ['./map-chart.component.scss'],
})
export class MapChartComponent implements OnChanges {
  @Output() select: EventEmitter<any> = new EventEmitter()
  @Input() data: any
  
  constructor(private http:HttpClient) {}

  ngOnChanges() {   
      if (!this.data) return
    
      let totalRevenue = this.data.reduce((acc, cur) => {
        return acc + cur.TotalMonthlyRevenue
      }, 0)
      this.data.forEach((x) => {
        x.TotalMonthlyRevenueShare = Math.round((x.TotalMonthlyRevenue * 10000.0) / totalRevenue) / 100
      })

      let map = am4core.create('mapChart', am4maps.MapChart)
      map.geodata = am4geodata_usaAlbersLow
      let polygonSeries = new am4maps.MapPolygonSeries()
      polygonSeries.useGeodata = true
      map.series.push(polygonSeries)

      let polygonTemplate = polygonSeries.mapPolygons.template
      polygonTemplate.tooltipText = '{name}'
      polygonTemplate.fill = am4core.color('#17a2b8')

      var imageSeries = map.series.push(new am4maps.MapImageSeries())
      imageSeries.data = this.data
      imageSeries.dataFields.value = 'TotalCustomers'

      var imageTemplate = imageSeries.mapImages.template
      imageTemplate.nonScaling = true

      imageTemplate.adapter.add('latitude', function (latitude, target) {
        var polygon = polygonSeries.getPolygonById((<any>target.dataItem.dataContext).id)

        if (polygon) {
          return polygon.visualLatitude
        }
        return latitude
      })

      imageTemplate.adapter.add('longitude', function (longitude, target) {
        var polygon = polygonSeries.getPolygonById((<any>target.dataItem.dataContext).id)

        if (polygon) {
          return polygon.visualLongitude
        }
        return longitude
      })

      var circle = imageTemplate.createChild(am4core.Circle)
      circle.fillOpacity = 0.7
      circle.fill = am4core.color('#DBA005')
      circle.tooltipText = '{State}: [bold]{value}[/]'
      circle.events.on('hit', (e) => {
        this.onMapChartSelect(e.target.dataItem.dataContext)
      })

      imageSeries.heatRules.push({
        target: circle,
        property: 'radius',
        min: 4,
        max: 30,
        dataField: 'value',
      })    
  }

  onMapChartSelect(dataItem) {
    this.select.emit(dataItem)
  }
}
