import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DocusignTemplateObject } from '../models/docusign.model';
import { LookupObject, ProposalProductLookupObject, StateLookupObject } from '../models/lookup.model';


@Injectable({
  providedIn: 'root',
})
export class LookupService {
  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl

  public is_docusign_templates_called: boolean = false
  private _docusign_templates_data: BehaviorSubject<Array<DocusignTemplateObject>> = new BehaviorSubject<
    Array<DocusignTemplateObject>
  >(new Array<DocusignTemplateObject>())

  public is_lead_statuses_called: boolean = false
  private _lead_statuses_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_lead_sources_called: boolean = false
  private _lead_sources_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_applicant_vendor_list_called: boolean = false
  private _applicant_vendor_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_customer_vendor_list_called: boolean = false
  private _customer_vendor_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_product_list_called: boolean = false
  private _product_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_proposal_product_list_called: boolean = false
  private _proposal_product_list_data: BehaviorSubject<Array<ProposalProductLookupObject>> = new BehaviorSubject<Array<ProposalProductLookupObject>>(new Array<ProposalProductLookupObject>())

  public is_brand_list_called: boolean = false
  private _brand_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_source_list_called: boolean = false
  private _source_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_otp_type_list_called: boolean = false
  private _otp_type_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_state_list_called: boolean = false
  private _state_list_data: BehaviorSubject<Array<StateLookupObject>> = new BehaviorSubject<Array<StateLookupObject>>(
    new Array<StateLookupObject>()
  )

  public is_common_pass_list_called: boolean = false
  private _common_pass_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_email_domain_list_called: boolean = false
  private _email_domain_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_email_account_list_called: boolean = false
  private _email_account_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_user_type_list_called: boolean = false
  private _user_type_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  public is_application_page_list_called: boolean = false
  private _application_page_list_data: BehaviorSubject<Array<LookupObject>> = new BehaviorSubject<Array<LookupObject>>(
    new Array<LookupObject>()
  )

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`)
    }
    // return an observable with a user-facing error message
    return throwError(error)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to get docusign templates
   */
  getDocusignTemplates(): Observable<Array<DocusignTemplateObject>> {
    if (!this.is_docusign_templates_called) {
      return this._httpClient.get<Array<DocusignTemplateObject>>(`${this._apiUrl}/document`).pipe(
        map((data: Array<DocusignTemplateObject>) => {
          this.is_docusign_templates_called = true
          this._docusign_templates_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._docusign_templates_data
    }
  }

  /**
   * Function to get lead statuses
   */
  getLeadStatuses(): Observable<Array<LookupObject>> {
    if (!this.is_lead_statuses_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/CustomerStatus`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_lead_statuses_called = true
          this._lead_statuses_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._lead_statuses_data
    }
  }

  /**
   * Function to get lead sources
   */
  getLeadSources(): Observable<Array<LookupObject>> {
    if (!this.is_lead_sources_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/Source`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_lead_sources_called = true
          this._lead_sources_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._lead_sources_data
    }
  }

  /**
   * Function to get customer vendors
   */
  getCustomerVendors(): Observable<Array<LookupObject>> {
    if (!this.is_customer_vendor_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/customer/vendor/list`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_customer_vendor_list_called = true
          this._customer_vendor_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._customer_vendor_list_data
    }
  }

  /**
   * Function to get products
   */
  getProducts(): Observable<Array<LookupObject>> {
    //if (!this.is_product_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/product/list`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_product_list_called = true
          this._product_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    // } else {
    //   return this._product_list_data
    // }
  }

  /**
   * Function to get applicant vendors
   */
  getApplicantVendors(): Observable<Array<LookupObject>> {
    if (!this.is_applicant_vendor_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/ApplicantVendor`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_applicant_vendor_list_called = true
          this._applicant_vendor_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._applicant_vendor_list_data
    }
  }

  /**
   * Function to get proposal products
   */
  getProposalProducts(): Observable<Array<ProposalProductLookupObject>> {
    //if (!this.is_proposal_product_list_called) {
      return this._httpClient
        .get<Array<ProposalProductLookupObject>>(`${this._apiUrl}/lookup/proposal/product/list`)
        .pipe(
          map((data: Array<ProposalProductLookupObject>) => {
            this.is_proposal_product_list_called = true
            this._proposal_product_list_data.next(data)
            return data
          }),
          catchError(this._handleError)
        )
    // } else {
    //   return this._proposal_product_list_data
    // }
  }

  /**
   * Function to get brands
   */
  getBrands(): Observable<Array<LookupObject>> {
    if (!this.is_brand_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/Brand`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_brand_list_called = true
          this._brand_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._brand_list_data
    }
  }

  /**
   * Function to get sources
   */
  getSources(): Observable<Array<LookupObject>> {
    if (!this.is_source_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/Source`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_source_list_called = true
          this._source_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._source_list_data
    }
  }

  /**
   * Function to get OTP types
   */
   getOtpTypes(): Observable<Array<LookupObject>> {
    if (!this.is_otp_type_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/otptype`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_otp_type_list_called = true
          this._otp_type_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._otp_type_list_data
    }
  }

   /**
   * Function to get states
   */
  getStates(): Observable<Array<StateLookupObject>> {
    if (!this.is_state_list_called) {
      return this._httpClient.get<Array<StateLookupObject>>(`${this._apiUrl}/State`).pipe(
        map((data: Array<StateLookupObject>) => {
          this.is_state_list_called = true
          this._state_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._state_list_data
    }
  }

  /**
   * Function to get Common Passwords List
   */
  getCommonPasswordsList(): Observable<Array<LookupObject>> {
    if (!this.is_common_pass_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/CommonPasswordsList`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_common_pass_list_called = true
          this._common_pass_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._common_pass_list_data
    }
  }

  /**
   * Function to get email domains list
   */
  getEmailDomainsList(): Observable<Array<LookupObject>> {
    if (!this.is_email_domain_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/email/domain/list`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_email_domain_list_called = true
          this._email_domain_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._email_domain_list_data
    }
  }

  /**
   * Function to get email accounts list
   */
  getEmailAccountsList(): Observable<Array<LookupObject>> {
    if (!this.is_email_account_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/email/account/list`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_email_account_list_called = true
          this._email_account_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._email_account_list_data
    }
  }

  /**
   * Function to get user types list
   */
  getUserTypesList(): Observable<Array<LookupObject>> {
    if (!this.is_user_type_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/user/type/list`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_user_type_list_called = true
          this._user_type_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._user_type_list_data
    }
  }

  /**
   * Function to get application pages list
   */
  getApplicationPagesList(): Observable<Array<LookupObject>> {
    if (!this.is_application_page_list_called) {
      return this._httpClient.get<Array<LookupObject>>(`${this._apiUrl}/lookup/application/page/list`).pipe(
        map((data: Array<LookupObject>) => {
          this.is_application_page_list_called = true
          this._application_page_list_data.next(data)
          return data
        }),
        catchError(this._handleError)
      )
    } else {
      return this._application_page_list_data
    }
  }
}
