import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Contact } from '../lead/profile/manage/customer-view-settings-form/customer-settings.model';
import { ApplicationPageResponseObject } from 'app/@core/models/application-page.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  selectedContact: Subject<Contact> = new Subject();
  closeSidebar: Subject<void> = new Subject();
  activeRowLead: any;
  refreshLeads: Subject<boolean> = new Subject();
  refreshTable: Subject<void> = new Subject();
  refreshManageTable: Subject<void> = new Subject();
  clearAssignedList: Subject<void> = new Subject();
  assignedLeads: Array<any> = [];
  toBeDeletedLeads: Array<any> = [];

  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loggedInUserId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  applicationPagesSubject: BehaviorSubject<Array<ApplicationPageResponseObject>> = new BehaviorSubject<Array<ApplicationPageResponseObject>>(new Array<ApplicationPageResponseObject>);

  allApplicationRoutesSubject: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>(new Array<string>);
  allApplicationRoutes$ = this.allApplicationRoutesSubject.asObservable();

  isLoggedInUserAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }
}
