import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs/Observable'

import { NbAuthService, NbAuthToken } from '@nebular/auth'
import { debug } from 'console'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: NbAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let tokenValue: string
    this.authService.getToken().subscribe((token: NbAuthToken) => {
      tokenValue = token.getValue()
    })

    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    req = req.clone({
      setHeaders: {
        Accept: 'application/json',
        Authorization: `Bearer ${tokenValue}`,
        TimeZone: timeZone
      },
    })

    return next.handle(req)
  }
}
