import { Component, Input, OnInit } from '@angular/core'
import { ViewCell } from 'ng2-smart-table'

@Component({
  selector: 'button-view',
  template: ` {{ value | date: format }}{{ !value ? 'N/A' : '' }} `,
})
export class DateViewComponent implements ViewCell, OnInit {
  @Input() value: string
  @Input() rowData: any
  format: string

  ngOnInit() {
    this.format = `M/d/yy hh:mm a ${(this.value || '').indexOf('+') !== -1 ? ' z' : ''}`
  }
}
