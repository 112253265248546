<div class="trend-chart__container">
  <div class="trend-chart__summary-label">
    <span *ngIf="trendChange < 1">
      <nb-icon status="danger" icon="arrow-downward-outline"></nb-icon>
      {{ trendChange * 100 }}% down since last year</span
    >
    <span *ngIf="trendChange == 1">No change since last year</span>
    <span *ngIf="trendChange > 1">
      <nb-icon status="success" icon="arrow-upward-outline"></nb-icon>
      {{ trendChange }}% up since last year</span
    >
  </div>
  <div class="trend-chart__wrapper" *ngIf="data.length" [id]="elementId"></div>
  <div class="trend-chart__wrapper" *ngIf="!data.length">No data to show.</div>
</div>
