import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core'
import {} from 'googlemaps'

@Component({
  selector: 'AutocompleteComponent',
  template: `
    <input
      class="input"
      type="text"
      [(ngModel)]="defaultValue"
      #addresstext
      (input)="onInput()"
      (blur)="onBlur()"
      style="padding: 0.5rem 1rem;
        border: 1px solid;
        width: 100%;
        background-color: #f7f9fc;
        border-color: #e4e9f2;
        color: #222b45;
          font-size: 0.8rem;"
      />
  `,
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() defaultValue: string = "test";
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() userTyped: EventEmitter<void> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;
  private userInteracted: boolean = false;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.getPlaceAutocomplete()
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
      componentRestrictions: { country: 'US' },
      types: [this.adressType], // 'establishment' / 'address' / 'geocode'
    })

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace()
      this.defaultValue = place.formatted_address.split(", USA")[0];
      this.userInteracted = false;
      this.invokeEvent(place)
    })
  }

  onInput() {
    this.userInteracted = true;
    this.userTyped.emit();
  }

  onBlur() {
    if (this.userInteracted) {
      this.defaultValue = ''; // Clear the field if the user typed without selecting an item
    }
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place)
  }
}
