import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'ngx-lead-contacts-sidebar',
  templateUrl: './lead-contacts-sidebar.component.html',
  styleUrls: ['./lead-contacts-sidebar.component.scss']
})
export class LeadContactsSidebarComponent implements OnInit {

  @Input() trigger: any;
  @Input() contactList: Array<any>;

  constructor(
    private _shatedSerice: SharedService,
  ){}

  ngOnInit(): void {
    
  }

  closeSidenav() {
    this._shatedSerice.closeSidebar.next();
  }

}
