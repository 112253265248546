import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { BaseComponent } from 'app/@core/base/base.component';
import { DocumentService, HttpHelperService, TranslateService } from 'app/@core/services';
import { DocusignService } from 'app/@core/services/docusign.service';
import { saveAs } from 'file-saver';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ngx-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent extends BaseComponent implements OnInit {

  /**
   * Input/Outputs
   */
  @Input() templateId: string;
  @Input() isTemplatePreview: boolean;
  @Input() isDocusignDocument: boolean;
  @Input() fileName: string;
  @Input() envelopeId: string;
  @Input() documentId: number;
  @Input() leadId: number;

  /**
   * Public variables
   */
  iframeSrc: SafeResourceUrl = null;
  isLoading: boolean = false;
  documentData: Blob;

  constructor(translateService: TranslateService,
    private _docusignService: DocusignService,
    private _documentService: DocumentService,
    public dialogRef: NbDialogRef<DocumentPreviewComponent>,
    public sanitizer: DomSanitizer,
    private _httpHelper: HttpHelperService,
    private _toastrService: NbToastrService,
  ) {
    super()
   }

  ngOnInit(): void {
    if (this.isTemplatePreview) {
      this.getDocusignTemplatePreviewUrl();
    }
    else {
      if(!this.isDocusignDocument) {
        this.getDocumentDataFromBlobStorage();
      }
      else {
        this.getDocumentDataFromDocusign();
      }
    }
  }

  /**
   * Function to get docusign template preview url
   */
  getDocusignTemplatePreviewUrl() {
    this.isLoading = true;
    this._docusignService.getDocusignTemplatePreviewUrl(this.templateId).pipe(take(1)).subscribe({
      next: (response: string) => {
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(response);
        this.isLoading = false;
      },
      error: (error) => {
        this._toastrService.danger(error.error.Content);
        this.isLoading = false;
      }
    })
  }

  /**
   * Function to get document data from blob storage
   */
  getDocumentDataFromBlobStorage() {
    this.isLoading = true;
    this._documentService.downloadFileFromBlobStorage(this.leadId, this.documentId).pipe(take(1)).subscribe({
      next: async (data: Blob) => {
        this.documentData = data;
        let documentUrl = window.URL.createObjectURL(data);
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
        this.isLoading = false;
      },
      error: (error) => {
        this._toastrService.danger(error.Content);
        this.dialogRef.close();
        this.isLoading = false;
      }
    })
  }

  /**
   * Function to get document data from docusign
   */
  getDocumentDataFromDocusign() {
    this.isLoading = true;
    this._docusignService.downloadSignedDocusignDocument(this.envelopeId).pipe(take(1)).subscribe({
      next: async (data: Blob) => {
        this.documentData = data;
        let documentUrl = window.URL.createObjectURL(data);
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
        this.isLoading = false;
      },
      error: (error) => {
        if(error.Content == 'This user is not the sender or a recipient of the envelope.  Only the sender or a recipient of the envelope may perform the requested operation.') {
          this._toastrService.danger('Unable to download file. Only the sender or a recipient of the envelope may perform the requested operation.');
        }
        else {
          this._toastrService.danger(error.Content);
        }
        this.dialogRef.close();
        this.isLoading = false;
      }
    })
  }

  downloadDocument() {
    saveAs(this.documentData, this.fileName);
  }

}
