import { Directive, ElementRef, Output, EventEmitter, HostListener, Input } from '@angular/core'

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Input() clickOutsideEnabled: boolean = true
  @Output() clickOutside = new EventEmitter()

  constructor(private _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this.clickOutsideEnabled) return

    if (!this._elementRef.nativeElement.contains(targetElement)) {
      this.clickOutside.emit(targetElement)
    }
  }
}
