import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LeadSiteObject, LeadSiteTableObject, ProposalSiteUpdateObject } from '../models/lead-site.model';
import { SiteExportMode } from '../enums/site-export-mode';
import { FieldObject } from '../models/shared.model';
import { StateLookupObject } from '../models/lookup.model';

@Injectable({
  providedIn: 'root'
})
export class LeadSiteService {

  /**
   * Public variables
   */
  reloadLeadSites: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  openSiteDialog: boolean = true;
  leadSiteId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  reloadLeadSiteFields: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  siteChangedSubject: Subject<boolean> = new Subject<boolean>();;
  private selectedSites: Array<LeadSiteTableObject> = new Array<LeadSiteTableObject>();
  visibleFieldsSubject = new BehaviorSubject<Array<FieldObject>>(null);
  visibleFields$ = this.visibleFieldsSubject.asObservable();

  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
  * Function to handle error
  * @param error
  * @returns
  */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`)
    }
    // return an observable with a user-facing error message
    return throwError(error)
  }

  /**
   * Function to parse error blob
   */
  private _parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      }
    });
    reader.readAsText(err.error);
    return obs;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
  * Function to get lead sites
  */
  public getLeadSites(leadId: number): Observable<Array<LeadSiteObject>> {
    return this._httpClient.get<Array<LeadSiteObject>>(`${environment.baseApiUrl}/lead/${leadId}/sites`).pipe(
      map((data: Array<LeadSiteObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
  * Function to save lead sites
  */
  public saveLeadSites(leadId: number, request: Array<LeadSiteObject>): Observable<string> {
    return this._httpClient.post<string>(`${environment.baseApiUrl}/lead/${leadId}/sites`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

   /**
  * Function to save Proposal sites
  */
   public saveProposalSites(leadId: number,proposalId: number,request: ProposalSiteUpdateObject): Observable<string> {
    return this._httpClient.post<string>(`${environment.baseApiUrl}/lead/${leadId}/${proposalId}/sites`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to export lead sites
   */
  public exportLeadSites(leadId: number, exportMode: SiteExportMode): Observable<Blob> {
    return this._httpClient.get(`${this._apiUrl}/lead/${leadId}/sites/export/${exportMode}`, { responseType: 'blob'}).pipe(
      map((data: Blob) => data),
      catchError(this._parseErrorBlob)
    );
  }

  /**
   * Function to get lead site custom fields
   */
  public getLeadSiteCustomFields(id: number): Observable<Array<FieldObject>> {
    return this._httpClient.get<Array<FieldObject>>(`${environment.baseApiUrl}/site/${id}/fields`).pipe(
      map((data: Array<FieldObject>) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to set lead site as default
   */
  public setDefaultLeadSite(leadId: number, siteId: number): Observable<string> {
    return this._httpClient.put<string>(`${environment.baseApiUrl}/lead/${leadId}/defaultSite/${siteId}`, {}).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to save lead site
   */
  public saveLeadSite(leadId: number, request: LeadSiteObject): Observable<string> {
    return this._httpClient.post<string>(`${environment.baseApiUrl}/lead/${leadId}/site`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to delete lead site
   */
  public deleteLeadSite(leadId: number, siteId: number): Observable<string> {
    return this._httpClient.delete<string>(`${environment.baseApiUrl}/lead/${leadId}/site/${siteId}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  /**
 * Function to delete multiple lead sites
 */
  public deleteListLeadSites(leadId: number, siteIds: Array<number>): Observable<string> {
    return this._httpClient.delete<string>(`${environment.baseApiUrl}/Site/${leadId}/list`, {body:{siteIds}}).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    );
  }

  setSelectedSites(sites: LeadSiteTableObject[]): void {
    this.selectedSites = sites;
  }

  getSelectedSites(): LeadSiteTableObject[] {
    return this.selectedSites;
  }

  /**
 * Function to get State Details
 */
  public getStateDetails(code: string): Observable<StateLookupObject> {
    return this._httpClient.get<StateLookupObject>(`${environment.baseApiUrl}/State/${code}`).pipe(
      map((data: StateLookupObject) => data),
      catchError(this._handleError)
    );
  }

  /**
   * Function to set visible fields
   */
  setVisibleFields(visibleFields: Array<FieldObject>) {
    this.visibleFieldsSubject.next(visibleFields);
  }
}
