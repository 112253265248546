<div class="profile-wrapper">
  <div class="profile-layout user-details">
    <nb-card class="min-height" [nbSpinner]="isLoading">
      <nb-card-header>
        <h5>My Details</h5>
      </nb-card-header>
      <nb-card-body>
        <form class="w-100 dialog-form" [formGroup]="userForm" autocomplete="off">
          <div class="field">
            <label class="required">First Name</label>
            <input nbInput fullWidth formControlName="firstName" name="firstName" autocomplete="new-password" [class.is-invalid]="checkIsFormControlValid('firstName')">
          </div>

          <div class="field">
            <label class="required">Last Name</label>
            <input nbInput fullWidth formControlName="lastName" name="lastName" [class.is-invalid]="checkIsFormControlValid('lastName')">
          </div>

          <div class="field">
            <label class="required">Email</label>
            <input nbInput fullWidth formControlName="email" name="email" autocomplete="new-password" [class.is-invalid]="checkIsFormControlValid('email')">
          </div>

          <div class="field">
            <button nbButton size="tiny" status="info" (click)="togglePasswordInputs()">Update Password</button>
          </div>
          <div *ngIf="showPasswordInputs">
            <div class="field">
              <label>Password</label>
              <div class="password-field">
                <input type="password" nbInput formControlName="password" fullWidth name="password" autocomplete="new-password" #passwordInput
                  [class.is-invalid]="checkIsFormControlValid('password')">
                <nb-icon class="pass-eye-icon"
                  [ngStyle]="userForm.get('password').invalid && (userForm.get('password').dirty || userForm.get('password').touched) ? { 'right':'35px' } : { 'right':'10px' }"
                  (click)="passwordInput.type = passwordInput.type === 'text' ? 'password' : 'text'"
                  [icon]="passwordInput.type === 'text' ? 'eye-off-outline' : 'eye-outline'"></nb-icon>
                <p class="error_message password-error" *ngIf="userForm.get('password').errors && !userForm.get('password').errors?.commonPass">Password must be at least 16 characters and contain at least one lowercase, one uppercase, one digit and must not contain repeated or consecutive characters.</p>
                <p class="error_message password-error" *ngIf="userForm.get('password').errors?.commonPass">You can not use a common password.</p>
              </div>
            </div>

            <div class="field">
              <label [class.required]="userForm.get('password').value">Confirm Password</label>
              <div class="password-field">
                <input type="password" nbInput formControlName="confirmPassword" fullWidth name="confirmPassword" autocomplete="new-password" #confirmPasswordInput
                  [class.is-invalid]="checkIsFormControlValid('confirmPassword')">
                <nb-icon class="pass-eye-icon"
                  [ngStyle]="userForm.get('confirmPassword').invalid && (userForm.get('confirmPassword').dirty || userForm.get('confirmPassword').touched) ? { 'right':'35px' } : { 'right':'10px' }"
                  (click)="confirmPasswordInput.type = confirmPasswordInput.type === 'text' ? 'password' : 'text'"
                  [icon]="confirmPasswordInput.type === 'text' ? 'eye-off-outline' : 'eye-outline'"></nb-icon>
                <p class="error_message password-error" *ngIf="userForm.get('confirmPassword').invalid && userForm.get('confirmPassword').touched">Passwords do not match.</p>
              </div>
            </div>
          </div>

          <div class="field">
            <label style="width: 145px !important;">Phone Number (with country code)
              <span class="lbl-required custom-lbl" *ngIf="this.userForm.get('preferredOTPType').value == otpTypeEnums.Sms">*</span>
            </label>
            <input nbInput fullWidth  formControlName="phoneNumber" name="phoneNumber" type="tel" [class.is-invalid]="checkIsFormControlValid('phoneNumber')">
          </div>

          <div class="field">
            <label>User Type</label>
            <input nbInput fullWidth  formControlName="userTypeId" name="userTypeId" type="text" readonly>
          </div>

          <div class="d-flex">
            <div class="field w-50">
              <label class="text-nowrap">Enable 2FA</label>
              <nb-icon status="primary" pack="eva" icon="shield-outline" class="fa-icon mr-3" [nbTooltip]="'Enable 2 Factor Authentication'"></nb-icon>
              <mat-slide-toggle appearance="outline" class="w-100 ml-4" formControlName="enable2FA" name="enable2FA"></mat-slide-toggle>
            </div>

            <div class="field" *ngIf="userForm.get('enable2FA').value">
              <label class="required text-nowrap">Sent OTP through:</label>
              <mat-radio-group formControlName="preferredOTPType">
                <mat-radio-button *ngFor="let otpType of otpTypes" [value]="otpType.Id" [checked]="otpType.Id === userForm.get('preferredOTPType').value"> {{ otpType.Name.toLowerCase() }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </form>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton size="small" status="primary" (click)="updateUser()" nbTooltip="Edit details" [disabled]="userForm.invalid || !isFormModified || saveInProgress" [nbSpinner]="saveInProgress">
        <nb-icon icon="edit"></nb-icon> Update
      </button>
      </nb-card-footer>
    </nb-card>
  </div>
  <div class="profile-layout integrations">
    <nb-card class="min-height">
      <nb-card-header>
        <h5>My Integrations</h5>
      </nb-card-header>
      <nb-card-body>
        <nb-card [nbSpinner]="loadingDocusign" nbSpinnerStatus="primary">
          <nb-card-header>
            <div class="header-container">
              <span class="header-text">DocuSign</span>
              <a href="/pages/my-profile" target="_blank" class="header-link">
                <nb-icon icon="external-link-outline" pack="eva" class="icon-with-padding"></nb-icon>
              </a>
            </div>
          </nb-card-header>
          <nb-card-body>
            <div class="integration__content">
              <div *ngIf="docusignIntegration.Id; else hasDocuSignIntegrations">
                <div class="integration__row">
                  <div class="integration">
                    <div
                      class="integration__indicator warning"
                      [ngClass]="{
                        warning: checkingStatus,
                        success: !checkingStatus && (status && status.IsValid === true),
                        error: !checkingStatus && status && !status.IsValid
                      }"
                    ></div>
                    <div *ngIf="checkingStatus; else hasStatus">
                      Checking {{ title }} authorisation...
                    </div>
                    <ng-template #hasStatus>
                      <div *ngIf="(status && status.IsValid)" class="integration__display">
                        <span>{{ title }} authorized</span>
                        <button nbButton size="tiny" status="info" (click)="resetAuthorization()">Reset Authorization</button>
                      </div>
                      <div *ngIf="status && !status.IsValid" class="integration__display">
                        <span>Your {{ title }} integration is missing or has been expired, please
                          authorize yourself</span>
                        <button nbButton size="tiny" status="success" (click)="authorizeIntegration()">Authorize</button>
                      </div>
                    </ng-template>
                  </div>
                  <button nbButton size="tiny" status="default"
                    (click)="openIntegrationEditor(docusignIntegration)">
                    Settings
                  </button>
                </div>
              </div>
              <ng-template #hasDocuSignIntegrations>
                <div *ngIf="!loadingDocusign">
                  <button nbButton status="info" size="tiny" (click)="addIntegration(docuSign.type)">
                    Setup DocuSign Integration
                  </button>

                  No DocuSign integration found.
                </div>
                <div *ngIf="loadingDocusign">
                  Loading DocuSign Integration.
                </div>
              </ng-template>
            </div>
          </nb-card-body>
        </nb-card>
      </nb-card-body>
    </nb-card>
  </div>
</div>
