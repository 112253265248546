<div class="container" (clickOutside)="onOutsideClick($event)">
  <div class="search-bar" [ngClass]="{'is-opened': isLoading || resultGroups != null}">
    <input fullWidth nbInput placeholder="Search" [(ngModel)]="filter" (keyup)="$event.keyCode === 13 && search()" />
    <button class="search-bar__reset" nbSuffix nbButton ghost status="danger" *ngIf="filter" (click)="reset()">
      <nb-icon icon="close-outline"></nb-icon>
    </button>
    <button nbSuffix nbButton ghost [disabled]="filter && filter.length < 3" (click)="search()">
      <nb-icon icon="search-outline"></nb-icon>
    </button>
  </div>
  <div class="result__container" *ngIf="isLoading">
    <div class="spinner" [nbSpinner]="isLoading" nbSpinnerStatus="success"></div>
  </div>
  <div class="result__container" *ngIf="!isLoading">
    <div class="result__group" *ngFor="let group of resultGroups | keyvalue">
      <label>{{ group.key }}</label>
      <div class="result__group-items">
        <div class="result__item" *ngFor="let item of group.value" (click)="onClick(item)">
          {{ item.Name }}
        </div>
      </div>
    </div>
  </div>
  <div class="result__container" *ngIf="!isLoading && isEmpty">
    <label class="no-results">No results.</label>
  </div>
</div>
