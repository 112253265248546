import { Directive, ElementRef, Output, EventEmitter, HostListener, Input } from '@angular/core'

@Directive({
  selector: '[dragScroll]',
})
export class DragScrollDirective {
  constructor(private _elementRef: ElementRef) {}
  @Input() dragScrollEnabled: boolean = true

  @Output() dragScrollStart = new EventEmitter()
  @Output() dragScroll = new EventEmitter()
  @Output() dragScrollEnd = new EventEmitter()

  dragStartPosition: any = null

  @HostListener('mousedown', ['$event'])
  public onDragStart(event) {
    if (!this.dragScrollEnabled) return

    this._elementRef.nativeElement.style.cursor = 'grabbing'
    this._elementRef.nativeElement.style.userSelect = 'none'

    this.dragStartPosition = {
      left: this._elementRef.nativeElement.scrollLeft,
      top: this._elementRef.nativeElement.scrollTop,
      x: event.clientX,
      y: event.clientY,
    }
    this.dragScrollStart.emit()
  }

  @HostListener('document:mousemove', ['$event'])
  public onDrag(event) {
    if (!this.dragScrollEnabled) this.dragStartPosition = null

    if (this.dragStartPosition) {
      const dx = event.clientX - this.dragStartPosition.x
      const dy = event.clientY - this.dragStartPosition.y
      this._elementRef.nativeElement.scrollTop = this.dragStartPosition.top - dy
      this._elementRef.nativeElement.scrollLeft = this.dragStartPosition.left - dx

      this.dragScroll.emit()
    }
  }

  @HostListener('document:mouseup', [])
  public onDragEnd() {
    if (this.dragStartPosition) {
      this.dragStartPosition = null
      this._elementRef.nativeElement.style.removeProperty('cursor')
      this._elementRef.nativeElement.style.removeProperty('user-select')
      this.dragScrollEnd.emit()
    }
  }
}
