import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'app/@core/utils';
import * as moment from 'moment';
import { Subscription, interval, timer } from 'rxjs';
import { NotificaitonAlert } from '../notifications-sidenav/notifications.model';
import { map, takeWhile } from 'rxjs/operators';
import { TimeAgoPipe } from '../time-ago.pipe';

@Component({
  selector: 'ngx-notification-group-bar',
  templateUrl: './notification-group-bar.component.html',
  styleUrls: ['./notification-group-bar.component.scss']
})
export class NotificationGroupBarComponent implements OnInit {

  @Input() group: NotificaitonAlert = new NotificaitonAlert();
  @Input() i: number;
  @Output() closeGroup: EventEmitter<any> = new EventEmitter();
  time: any = '';
  subscription: Subscription;

  countDownMinutes: number;
  countDownSeconds: number;


  constructor(
    private layoutService: LayoutService,
    private timeAgoPipe: TimeAgoPipe,
    private _cdRef: ChangeDetectorRef,
    private router: Router) {
    }

  ngOnInit(): void {
    this.group.timeAgoIso = moment().toISOString();

    this.checkTime();
    const source = interval(10000);
    this.subscription = source.subscribe(val => this.checkTime());

    if (this.group && this.group.events[0]) {
      this.startCountdown(this.group.events[0].messageProcessingDelaySeconds); 
    }
  }

  startCountdown(totalSeconds: number): void {
    let remainingSeconds = totalSeconds;

    const countdownInterval = setInterval(() => {
      this.countDownMinutes = Math.floor(remainingSeconds / 60);
      this.countDownSeconds = remainingSeconds % 60;

      remainingSeconds--;

      if (remainingSeconds < 0) {
        clearInterval(countdownInterval);
      }
    }, 1000);
  }

  checkTime() {
    this.group.timeAgo = moment(this.group.timeAgoIso).fromNow();

    this.group.timeAgo = this.group.fromApi ? this.timeAgoPipe.transform(this.group.CreatedDate) : moment(this.group.timeAgoIso).fromNow();
  }

  closeBar() {
    this.closeGroup.emit(
      {
        groupIndex: this.group.id
      }
    );
  }

  barBg() {
    switch(this.group.typeName) {
      case 'Event Processor': {
        return '#ffffff';
      }
      case 'Docusign': {
        return '#3366ff';
      }
      default: {
        return '#ffffff';
      }
    }
  }

  dateColor() {
    switch(this.group.typeName) {
      case 'Event Processor': {
        return '#808080';
      }
      case 'Docusign': {
        return '#ffffff';
      }
      default: {
        return '#808080';
      }
    }
  }

}
