import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from './../../../environments/environment'
import { Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { Vendor } from 'app/pages/shared/shared.model'
import { VendorSiteColumnOrder } from '../models/vendor-site-column-order.model'

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  /**
   * Private variables
   */
  private _apiUrl: string = environment.baseApiUrl

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`)
    }
    // return an observable with a user-facing error message
    return throwError(error)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Function to delete Vendor template
   */
  public deleteVendorTemplate(): Observable<boolean> {
    return this._httpClient.delete<boolean>(`${this._apiUrl}/Vendor/template`).pipe(
      map((data: boolean) => data),
      catchError(this._handleError)
    )
  }

  public getAll(): Observable<Array<Vendor>> {
    return this._httpClient.get<Array<Vendor>>(`${this._apiUrl}/vendor`).pipe(
      map((data: Array<Vendor>) => data),
      catchError(this._handleError)
  )}

  /**
   * Function to save vendor templates
   */
  public saveVendorTemplate(vendorId: number, templateId: string): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/vendor/${vendorId}/template/${templateId}`, null).pipe(
      map((data: string) => data),
      catchError(this._handleError)
  )}

  /**
   * Function to delete vendor
   */
  public deleteVendor(vendorId): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/vendor/${vendorId}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
  )}

  /**
   * Function to get vendor's site table columns order
   */
  public getVendorsSiteTableColumnsOrder(vendorId: number): Observable<Array<VendorSiteColumnOrder>> {
    return this._httpClient.get<Array<VendorSiteColumnOrder>>(`${this._apiUrl}/vendor/${vendorId}/site/table/columns/order`).pipe(
      map((data: Array<VendorSiteColumnOrder>) => data),
      catchError(this._handleError)
  )}

  /**
   * Function to save vendor's site table columns order
   */
  public saveVendorsSiteTableColumnsOrder(vendorId: number, request: Array<VendorSiteColumnOrder>): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}/vendor/${vendorId}/site/table/columns/order`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
  )}

   /**
   * Function to check if vendor exists
   */
  public checkVendorExists(vendorName: string, vendorId: number, sectorId: number): Observable<any> {
    let url = `${this._apiUrl}/vendor/${vendorName}/exist?sectorId=${sectorId}`;
    if (vendorId !== undefined && vendorId !== null) {
      url += `&vendorId=${vendorId}`;
    }

    return this._httpClient.get<any>(url).pipe(
      map((data: any) => data),
      catchError(this._handleError)
    );
  }
}
