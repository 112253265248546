<div class="initials-in-table" 
customToolTip
[contentTemplate]="template" 
[style.backgroundColor]="contact.bgColor" 
(click)="onContactSelected(contact)">{{contact.FirstName | initials}}{{contact.LastName | initials}}
</div>

<ng-template #template>
    <div style="display: flex; flex-direction: column">
      <div [innerHTML]="sampleHtml"></div>
    </div>
</ng-template>
