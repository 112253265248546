import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string): string {
    const currentDate = new Date();
    const inputDate = new Date(value);

    const seconds = Math.floor((currentDate.getTime() - inputDate.getTime()) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval == 1) {
      return "1 year ago";
    } else
    if (interval > 1) {
      return interval + " years ago";
    }

    interval = Math.floor(seconds / 2592000);
    if (interval == 1) {
      return "1 month ago";
    } else
    if (interval > 1) {
      return interval + " months ago";
    }

    interval = Math.floor(seconds / 86400);
    if (interval == 1) {
      return "1 day ago";
    } else
    if (interval > 1) {
      return interval + " days ago";
    }

    interval = Math.floor(seconds / 3600);
    if (interval == 1) {
      return "1 hour ago";
    } else
    if (interval > 1) {
      return interval + " hours ago";
    }

    interval = Math.floor(seconds / 60);
    if (interval == 1) {
      return "1 minute ago";
    } else
    if (interval > 1) {
      return interval + " minutes ago";
    }

    return Math.floor(seconds) + " seconds ago";
  }

}
