import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from '../shared.service';
import { BaseComponent } from 'app/@core/base/base.component';

@Component({
  selector: 'ngx-lead-contacts',
  templateUrl: './lead-contacts.component.html',
  styleUrls: ['./lead-contacts.component.scss']
})
export class LeadContactsComponent extends BaseComponent  implements OnInit{

  @Input() contactList: Array<any> = [];
  @Output() expandSubrow: EventEmitter<boolean> = new EventEmitter()
  @Input() activeSubrow: number;
  @Input() index: number;

  constructor(
    private _sharedService: SharedService,
  ){
    super()
  }

  ngOnInit(): void {
  }

  addContact(){
    this.expandSubrow.emit(true);
  }
}
