import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  /**
   * Public variables
   */
  @Input() message_1: string;
  @Input() message_2: string;
  @Input() timer: number;

  minutes: number;
  seconds: number;
  timeLapsed:boolean = false;

  constructor(private _dialogRef: NbDialogRef<ConfirmDialogComponent>) {
    this.minutes = Math.floor(this.timer / 60);
    this.seconds = this.timer % 60;
  }

  ngOnInit(): void {
    this.calculateWaitingTime();
  }

  /**
   * Function to calculate waiting time
   */
  calculateWaitingTime() {
    if(this.timer >= 60) {
      this.minutes = Math.floor(this.timer / 60);
    }
    else {
      this.minutes = 0;
    }
    this.seconds = this.timer >= 60 ? this.timer % 60 : this.timer;
    this.timer--;
    setTimeout(() => {
      this.calculateWaitingTime();
    }, 1000);

    this.timeLapsed = this.timer < 0 || this.timer == 0? true : false;
  }

  proceed(action: boolean) {
    this._dialogRef.close(action);
  }

}
