import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { NgSelectComponent } from '@ng-select/ng-select'

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true,
    },
  ],
})
export class CustomSelectComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('select') select: NgSelectComponent

  @Input() value: string
  @Input() disabled: boolean = false

  @Input() items: any = []
  @Input() keyField: string = 'Id'
  @Input() valueField: string = 'Name'
  @Input() placeHolder: string = 'None'
  @Input() notFoundText: string = 'No items found'
  @Input() customClass: string
  @Input() useItemAsValue:boolean = false
  @Input() clearable: boolean = true

  @Output() change = new EventEmitter()

  constructor() {}

  onBlur = (_) => {}
  onChange: (value: string) => {}

  writeValue(obj: any): void {
    this.value = obj
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn
  }

  onChangeInternal() {
    this.onChange(this.value)
    this.select.blur()
    this.change.emit(this.value)
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  ngAfterViewInit() {
    this.select.blur()
  }
}
