<nb-card>
  <nb-card-header>Signer Details <nb-icon class="close-icon" icon="close" (click)="dialogRef.close()"> </nb-icon></nb-card-header>
  <nb-card-body>
      <div *ngIf="signerDetails">
          <div class="field justify-content-start">
              <label class="mb-0">Full Name:</label>
              <span>{{ signerDetails.FirstName + " " + signerDetails.LastName }}</span>
          </div>
          <div class="field justify-content-start">
              <label class="mb-0">Email:</label>
              <span>{{ signerDetails.Email }}</span>
          </div>
          <div class="field justify-content-start">
              <label class="mb-0">Title:</label>
              <span>{{ signerDetails.Position }}</span>
          </div>
          <div class="field justify-content-start">
              <label class="mb-0">Signed Date:</label>
              <span>{{ _signedDate }}</span>
          </div>
      </div>
  </nb-card-body>
  <nb-card-footer>
      <button class="cancel" nbButton size="tiny" status="success" (click)="dialogRef.close()">Close</button>
  </nb-card-footer>
</nb-card>
