import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApplicationPageResponseObject, CreateApplicationPageRequestObject, CreateUserApplicationPageRequestObject, UpdateApplicationPageRequestObject, UpdateUserApplicationPageRequestObject, UserApplicationPageResponseObject } from '../models/application-page.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationPageService {
  /**
   * Private variables
   */
  private _apiUrl: string = `${environment.baseApiUrl}/application/page`

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error
   * @param error
   * @returns
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`)
    }
    // return an observable with a user-facing error message
    return throwError(error)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to get all application pages
   */
  public getApplicationPages(): Observable<Array<ApplicationPageResponseObject>> {
    return this._httpClient.get<Array<ApplicationPageResponseObject>>(`${this._apiUrl}/list`).pipe(
      map((data: Array<ApplicationPageResponseObject>) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to get application sub-pages
   */
  public getApplicationSubPages(parentId: number): Observable<Array<ApplicationPageResponseObject>> {
    return this._httpClient.get<Array<ApplicationPageResponseObject>>(`${this._apiUrl}/list/${parentId}`).pipe(
      map((data: Array<ApplicationPageResponseObject>) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to create application page
   */
  public createApplicationPage(request: CreateApplicationPageRequestObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to update application page
   */
  public updateApplicationPage(request: UpdateApplicationPageRequestObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to delete application page
   */
  public deleteApplicationPage(id: number): Observable<string> {
    return this._httpClient.delete<string>(`${this._apiUrl}/${id}`).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to create user application page
   */
  public createUserApplicationPage(request: CreateUserApplicationPageRequestObject): Observable<string> {
    return this._httpClient.post<string>(`${this._apiUrl}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to update user application page
   */
  public updateUserApplicationPage(request: UpdateUserApplicationPageRequestObject): Observable<string> {
    return this._httpClient.put<string>(`${this._apiUrl}`, request).pipe(
      map((data: string) => data),
      catchError(this._handleError)
    )
  }

  /**
   * Function to get application pages and routes
   */
  public getUserApplicationPagesAndRoutes(): Observable<UserApplicationPageResponseObject> {
    return this._httpClient.get<UserApplicationPageResponseObject>(`${this._apiUrl}/user`).pipe(
      map((data: UserApplicationPageResponseObject) => data),
      catchError(this._handleError)
    )
  }
}
